import { Injectable } from '@angular/core';
import { EventService, ScApiService } from '@sc-ui';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ChatsService {

  private _isInitialized = false;

  private _onStop = new Subject<any>();

  public onMessageReceived = new Subject<any>();

  public onDisconnected = new Subject<any>();

  public onReconnected = new Subject<any>();

  constructor(private api: ScApiService, private events: EventService) { }

  public initialize()
  {
    if (this._isInitialized) return;
    this._isInitialized = true;

    this.events.channel(`${this.api.userId}/${this.api.userToken}/messages`).pipe(takeUntil(this._onStop)).subscribe((args: any) => {
      this.onMessageReceived.next(args);
    });

    this.events.disconnected.pipe(takeUntil(this._onStop)).subscribe(() => {
      this.onDisconnected.next();
      this.events.connected.pipe(take(1)).subscribe(() => {
        console.log('chats service reconnected');
        this.onReconnected.next();
      });
    });
  }

  public stop()
  {
    this._isInitialized = false;
    this.events.unsubscribeAll();
    this._onStop.next();
  }

  public getChats(resource: string = 'chats') {
    return this.api.get(resource).pipe(tap((d: any) => {
      d.forEach((c: any) => { this.initializeChat(c); });
    }));
  }

  public postChat(resource: string, payload: any) {
    return this.api.post(resource, payload).pipe(tap((c: any) => {
      this.initializeChat(c);
    }));
  }

  public getChat(resource: string) {
    return this.api.get(resource).pipe(tap((c: any) => {
      this.initializeChat(c);
    }));
  }

  private acknowledge(chat: any) {
    chat.UnreadMessageCount = 0;
    chat.LastReadMessageIndex = chat.CurrentMessageIndex;
    chat.onAcknowledged.next();
    this.api.post(`chats/${chat.Id}/acknowledge`, null).subscribe();
  }

  private initializeChat(chat) {
    chat.getMessages = (offset, take) => this.getMessages(chat, offset, take);
    chat.acknowledge = () => this.acknowledge(chat);
    chat.sendMessage = (message) => this.sendMessage(chat, message);
    chat.onMessageReceived = new Subject<any>();
    chat.onAcknowledged = new Subject<any>();
    chat.onDisconnected = this.onDisconnected;
    chat.onReconnected = this.onReconnected;

    this.onMessageReceived
      .pipe(filter((args: any) => {
        return args.Payload.Id == chat.Id;
      }))
      .subscribe((args: any) => {
        chat.UnreadMessageCount = args.Payload.UnreadMessageCount;
        chat.CurrentMessageIndex = args.Payload.CurrentMessageIndex;
        chat.onMessageReceived.next(args.Payload);
      });
  }

  private getMessages(chat: any, startIndex: number, endIndex: number) {
    return this.api
      .get(`chats/${chat.Id}/messages?startIndex=${startIndex}&endIndex=${endIndex}`)
      .pipe(tap((d: any) => {
        if (!d.length) return;
        var newestMessage = d[0];
        if (chat.LastMessage && chat.LastMessage.Index > newestMessage.Index) return
        chat.LastMessage = newestMessage;
        chat.Changed = newestMessage.Timestamp;
        chat.CurrentMessageIndex = newestMessage.Index;
      }));
  }

  private sendMessage(chat: any, message: any) {

    var isoNow = (new Date()).toISOString();
    message.Timestamp = isoNow;

    return this.api
      .post(`chats/${chat.Id}/messages`, message)
      .pipe(tap((d: any) => {
        message.Index = d.Index;
        chat.Changed = message.Timestamp;
        chat.LastMessage = message;
        chat.CurrentMessageIndex = d.Index;
      }));
  }
}
