<div class="container p-0">
    <div class="row align-items-end">
      <div class="col">
        <textarea #multilineInput rows="1" class="form-control form-control-text me-sm-3 mb-0" [(ngModel)]="textInput" (ngModelChange)="resizeTextArea()"></textarea>
      </div>
      <div class="col-auto pl-0">
        <button class="btn btn-primary btn-circle send-button" (click)="send()">
          <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
        </button>
      </div>
    </div>