<section anchor="contact" id="contact">

  <div class="container pt-4 pt-lg-0">

    <div class="row align-items-center">

      <div class="col-12 order-1 col-md-6 order-md-2 offset-md-1">
 
        <div class="row">

          <div class="col-12 col-lg-10">
            <h2>Möchten Sie uns lieber <br /> persönlich kennenlernen?</h2>
        
            <p class="larger">
              Egal ob Sie nur 15 Minuten haben oder es gerne genau wissen wollen: Jonas Krömer freut sich darauf,
              Sie in einem Videocall kennenzulernen und mehr über Ihre Herausforderungen zu erfahren!
            </p>

            <button class="btn btn-primary btn-round btn-lg mt-4" (click)="goToBooking()">Termin buchen</button>

          </div>

        </div>
      </div>

      <div class="col-10 offset-2 mt-6 mt-sm-2 col-md-5 offset-md-0 mt-md-0 order-2 order-md-1 d-flex" style="justify-content: right;">
        <picture>
          <!-- <source srcset="assets/img/job-check-lp/jonas-kroemer.webp" type="image/webp">
          <source srcset="assets/img/job-check-lp/jonas-kroemer.png" type="image/png"> -->
          <img src="assets/img/jonas-kroemer-termin.png" alt="Jonas Krömer von skillconomy" width="328" height="326" class="img-fluid w-100">
        </picture>

        <!-- <p class="large"><strong>Jonas Krömer</strong><br> Head of Customer Success</p> -->
      </div>

    </div>

  </div>

</section>
