import { Component } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { JobCheckStepBaseComponent } from 'src/components/job-check-step-base/job-check-step-base.component';

@Component({
  selector: 'app-feedback-step-five',
  templateUrl: './feedback-step-five.component.html',
  styleUrls: ['./feedback-step-five.component.scss']
})
export class FeedbackStepFiveComponent extends JobCheckStepBaseComponent {

isSending = false;
  
  constructor(private api: ScApiService) {
    super();
  }

  override initialize() {

    if (!this.data.Comment) 
    {
      this.data.Comment = '';
    }    
  }

  sendFeedback() {
    this.isSending = true;
    this.api.post('projects/' + this.data.secret + '/rate', this.data).subscribe((d) => {
      this.complete.next();
    },
    () => { this.isSending = false },
    () => { this.isSending = false });
  }

}
