<section class="teaser-posts">

  <div class="container">

    <div class="row">

      <div class="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">

        <h2 class="heading-lg heading-serif mb-6 pb-5">
          Blog
        </h2>

        <app-blog-preview [posts]="posts"></app-blog-preview>

      </div>

    </div>

    <div class="row align-items-center justify-content-center mt-5">
      <div class="col-auto">
        <button class="btn btn-primary btn-round btn-lg" routerLink="/blog">Zum Blog</button>
      </div>
    </div>

  </div>

</section>
