import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-job-check-cta-card',
  templateUrl: './job-check-cta-card.component.html',
  styleUrls: ['./job-check-cta-card.component.scss']
})
export class JobCheckCtaCardComponent {

  @Input()
  ctaUrl = '/stellencheck';

}
