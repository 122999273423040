import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ScScrollService } from '@sc-ui';

declare var Rellax : any;

@Component({
  selector: 'app-home-hero',
  templateUrl: './home-hero.component.html',
  styleUrls: ['./home-hero.component.scss']
})
export class HomeHeroComponent implements OnInit {


  @ViewChild('video')
  video : ElementRef;

  isVideoPlaying = false;
  now = new Date();

  constructor(public scroll : ScScrollService) { }

  ngOnInit() {
    if(typeof Rellax === "undefined") 
    {
      console.log('Rellax not defined');
    }
    else
    {
      var rellax = new Rellax('.rellax');
    }    
  }

  playVideo()
  {
    this.isVideoPlaying = true;
    this.video.nativeElement.play();
  }
}
