<div class="card card-lg" [class.d-none]="!active" *ngIf="data">

	<div class="card-header border-bottom-0 pb-0">
  
	  <div class="row">
  
		<div class="col-12 mb-3">
		  <div class="d-md-none smaller text-muted">1 | 7</div>
		</div>
  
		<div class="col-auto">
		  <h2 class="card-header-title h2">Die Bewerbungen waren aussagekräftig und passend</h2>
		</div>
  
	  </div>
  
	</div>
  
	<div class="card-body">
	  <div class="form-group">
		<div class="custom-control custom-radio custom-btn-radio mb-3" (click)="selectedOption = option; onChange()" *ngFor="let option of options; let i = index;">
		  <input type="radio" class="custom-control-input" [checked]="selectedOption == option" id="customRadioRemote{{i}}" name="customRadioRemote{{i}}">
		  <label class="custom-control-label btn btn-secondary btn-block btn-lg text-start" for="customRadioRemote{{i}}">{{ option.Label }}</label>
		</div>
	  </div>
	</div>
  
	<div class="card-footer border-top-0 pt-0">
  
	  <div class="row justify-content-between align-items-center">
  
		<div class="col-auto"><a class="text-muted fw-bold clickable" (click)="back.next()"><i class="fa-sharp fa-solid fa-chevron-left fa-xs me-2"></i>zurück</a></div>
		<div class="col-auto d-none d-md-block">Schritt 3 von 4</div>
		<div class="col-auto"><button class="btn btn-primary btn-round btn-lg" (click)="onComplete()" [disabled]="!selectedOption">Weiter</button></div>
  
	  </div>
  
	</div>
  
  </div>