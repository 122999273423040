import { Component, OnInit, Input } from '@angular/core';
import { ScScrollService } from '@sc-ui';

@Component({
  selector: 'app-header-no-nav',
  templateUrl: './header-no-nav.component.html',
  styleUrls: ['./header-no-nav.component.scss']
})
export class HeaderNoNavComponent implements OnInit {

  scrolled = false;
  
  constructor(scrollService: ScScrollService) {

    scrollService.$scrollYChange.subscribe(y => {
      this.scrolled = y > 400;
    });

    this.scrolled = scrollService.scrollY > 400;
  }

  ngOnInit(): void {
  }

}
