<section *ngIf="id">
        
      <div class="container mb-6">
        <div class="row">

          <div class="col-12 col-md-8 col-lg-8 offset-lg-0">

            <div class="post-header">
              <div class="h5 mb-3">{{post?.Category}}</div>
              <h2 class="mb-5 heading-xl" [innerHTML]="post?.Headline"></h2>
            </div>

            <div class="post-meta">
              <div class="row align-items-center">
       
                <div class="col-auto">
                  <div class="row align-items-center">
                    <div class="image col-auto">
                      <sc-avatar size="xs" [user]="post.Author"></sc-avatar>
                    </div>
                    <div class="name col ms-n3">
                      <div class="name fw-bold">{{ post.Author.Firstname }} {{ post.Author.Lastname }}</div>
                    </div>
                  </div>

                </div>

                <div class="col-auto">
                
                  <div class="timestamp">{{ post.Timestamp | date : 'dd. MMMM yyyy' : undefined : 'de-DE' }}</div>
                
                </div>

              </div>


            </div>
          </div>

        </div>
      </div>


      <div class="container-fluid mb-6">

        <div class="row">

          <div class="col-12">
            <img src="/assets/blog/{{id}}/banner.jpg" class="img-fluid post-banner" />
          </div>

        </div>

      </div>

      <div class="container">

        <div class="row">

          <div class="col-12 col-lg-6 offset-lg-3">

               <div class="post-content" [innerHTML]="content"></div>               
               <a (click)="back();" class="link-arrow left text-uppercase small">Zurück</a>

          </div>


        </div>

      </div>




</section>

<section class="related-posts" *ngIf="relatedPosts?.length">

  <div class="container">

    <div class="row">

      <div class="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">

        <h2 class="heading-lg heading-serif mb-6 pb-5">
          Ähnliche Posts
        </h2>

        <app-blog-preview [posts]="relatedPosts"></app-blog-preview>

      </div>

    </div>

  </div>

</section>

<app-job-check-cta></app-job-check-cta>

<app-contact-form></app-contact-form>