 <div class="topic-items-grid pt-6">
   <a class="topic-item" *ngFor="let item of items" [routerLink]="'/themen/' + item.path">
     <p>
       {{item.data?.preTitle}}
     </p>
     <h3 class="mb-5">
       {{item.data?.title}}
     </h3>

     <span class="link-arrow right heading-uppercase">Mehr</span>

   </a>
 </div>
