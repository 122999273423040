export const model = {
  "title": "skillconomy | Impressum",
  "bodyClasses": [ "imprint-page" ],
  "hero": {
    "headline": "Impressum\n"
  },
  "contentblocks": [
    {
      "type": "text",
      "col": [
        {
          "headline": "Angaben gemäß § 5 TMG:",
          "class": "col-12 col-md-6 mb-5",
          "text": "address"
        },
        {
          "headline": "Kontakt",
          "class": "col-12 col-md-5 offset-md-1 col-lg-6 offset-lg-0",
          "text": "contact"
        }
      ]
    },
    {
      "type": "text",
      "class": "col-12 col-md-8 col-lg-4",
      "headline": "Geschäftsführer:",
      "text": {
        "class": null,
        "html": "<p>Lars Branscheid, Marc Branscheid, <br>Jonas Krömer</p>\n"
      }
    },
    {
      "type": "text",
      "class": "col-12 col-md-8 col-lg-5",
      "headline": "Verantwortlich für den Inhalt nach §55 Abs. 2 RStV:",
      "text": {
        "class": null,
        "html": "<p>Lars Branscheid</p>\n"
      }
    },
    {
      "type": "text",
      "class": "col-12 col-md-8 col-lg-4",
      "headline": "Handelsregister",
      "text": {
        "class": null,
        "html": "<p>HRB 15517</p>\n"
      }
    },
    {
      "type": "text",
      "class": "col-12 col-md-8 col-lg-4",
      "headline": "Umsatzsteuer-ID gemäß §27a Umsatzsteuergesetz",
      "text": {
        "class": null,
        "html": "<p>DE301461523</p>\n"
      }
    },
    {
      "type": "text",
      "class": "col-12 col-md-10 col-lg-8",
      "headline": "Haftungsbeschränkung",
      "text": {
        "class": null,
        "html": "<p>Die Webseite wurde mit größtmöglicher Sorgfalt erstellt.  Der Anbieter dieser Webseite übernimmt dennoch keine Gewähr  für die Richtigkeit, Vollständigkeit und Aktualität der  bereitgestellten Inhalte und Informationen. Die Nutzung der  Webseiteninhalte erfolgt auf eigene Gefahr. Allein durch  die Nutzung der Website kommt keinerlei Vertragsverhältnis  zwischen dem Nutzer und dem Anbieter zustande.</p>\n"
      }
    },
    {
      "type": "text",
      "class": "col-12 col-md-10 col-lg-8",
      "headline": "Verlinkungen",
      "text": {
        "class": null,
        "html": "<p>Die Webseite enthält Verlinkungen zu anderen Webseiten (\"externe Links\").  Diese Webseiten unterliegen der Haftung der jeweiligen Seitenbetreiber. Bei  Verknüpfung der externen Links waren keine Rechtsverstöße ersichtlich.  Auf die aktuelle und künftige Gestaltung der verlinkten Seiten hat der  Anbieter keinen Einfluss. Die permanente überprüfung der externen Links  ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar.  Bei Bekanntwerden von Rechtsverstößen werden die betroffenen externen Links  unverzüglich gelöscht.</p>\n"
      }
    },
    {
      "type": "text",
      "class": "col-12 col-md-10 col-lg-8",
      "headline": "Urheberrecht / <br>Leistungsschutzrecht",
      "text": {
        "class": null,
        "html": "<p>Die auf dieser Webseite durch den Anbieter veröffentlichten Inhalte  unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Alle  vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene  Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters  oder jeweiligen Rechteinhabers. Dies gilt vor allem für Vervielfältigung,  Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe  von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen.  Dabei sind Inhalte und Rechte Dritter als solche gekennzeichnet. Das  unerlaubte Kopieren der Webseiteninhalte oder der kompletten Webseite ist  nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und  Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch  ist erlaubt.</p>\n<p>Diese Website darf ohne schriftliche Erlaubnis nicht durch Dritte in  Frames oder iFrames dargestellt werden. </p>\n"
      }
    }
  ]
};