import { Component, OnInit } from '@angular/core';
import { ScScrollService } from '@sc-ui';

@Component({
  selector: 'app-home-faq',
  templateUrl: './home-faq.component.html',
  styleUrls: ['./home-faq.component.scss']
})
export class HomeFaqComponent implements OnInit {

  constructor(public scroll : ScScrollService) { }

  ngOnInit() {
  }

}
