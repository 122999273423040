<div class="chatbot-message sender-user" [class.sender-user]="isOwnMessage" [class.sender-server]="!isOwnMessage" *ngIf="message && !(message.Type == 'None' && message.Direction == 'UserToBot')" [@messageAnimate]="_visible" [class.is-history]="message?.History">
    <div class="message-wrapper">
        <sc-avatar class="message-avatar" *ngIf="message.Sender && !isOwnMessage" size="xs" [user]="message.Sender"></sc-avatar>

        <div class="message-content">

            <div class="message-sender" *ngIf="message.Sender">
                {{ message.Sender.FirstName }} {{ message.Sender.LastName }}
            </div>

            <div class="message-body">
                <sc-chatbot-answer-text *ngIf="message.Direction != 'UserToBot'" [message]="message"></sc-chatbot-answer-text>
                <sc-chatbot-answer-text *ngIf="message.Type == 'YesNo' && message.Direction == 'UserToBot'" [message]="message"></sc-chatbot-answer-text>
                <sc-chatbot-answer-text *ngIf="message.Type == 'Text' && message.Direction == 'UserToBot'" [message]="message"></sc-chatbot-answer-text>
                <sc-chatbot-answer-choice *ngIf="message.Type == 'Choice' && message.Direction == 'UserToBot'" [message]="message"></sc-chatbot-answer-choice>
                <sc-chatbot-answer-map *ngIf="message.Type == 'Location' && message.Direction == 'UserToBot'" [message]="message"></sc-chatbot-answer-map>
                <sc-chatbot-answer-file-upload *ngIf="message.Type == 'FileUpload' && message.Direction == 'UserToBot'" [message]="message"></sc-chatbot-answer-file-upload>
            </div>

            <div class="message-status" *ngIf="isOwnMessage">

                <div class="check-icon" [class.show]="message.isSent">
                  <i class="fa-sharp fa-solid fa-circle-check me-2"></i>
                </div>

 
                <a class="badge badge-danger-soft mt-2" *ngIf="message.hasSendingFailed" (click)="resendMessage()">
                   Fehler! Erneut senden.
                </a>

            </div>
        </div>
    </div>
</div>