import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/services/blog.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent implements OnInit {

  id : string;
 
  post : any = "";

  relatedPosts : any = [];

  content : any;

  author = {
    FirstName: "Marc",
    LastName: "Branscheid",
    Image: "https://screcruiting.blob.core.windows.net/publicassets/marc-avatar.jpg"
  }

  constructor(
    private http: HttpClient, 
    public blogService : BlogService, 
    private route : ActivatedRoute, 
    private router : Router, 
    private sanitizer : DomSanitizer, 
    private location: Location) { 

    }

  ngOnInit(): void {

    this.route.params.subscribe((params : any) => {
      
      this.id = params.id;
 
      this.content = null;
      this.post = this.blogService.getPost(this.id);
      this.relatedPosts = this.blogService.postsByCategory[this.post.Category]?.filter(i => i.Uri != this.post.Uri);

      this.http.get(`/assets/blog/${this.id}/post.html`, { responseType: 'text' }).subscribe((d : string) => {
        this.content = this.sanitizer.bypassSecurityTrustHtml(d);
      });
    })
  }

  back()  {

    var state : any = this.location.getState();

    if (state.navigationId > 1)
    {
      this.location.back();
    }
    else
    {
      this.router.navigateByUrl('/blog');
    }
  }

}
