<app-ebner-stolz-hero [variant]="variant"></app-ebner-stolz-hero>

<app-ebner-stolz-benefits></app-ebner-stolz-benefits>

<app-ebner-stolz-cta (clicked)="showChatbot()"></app-ebner-stolz-cta>

<app-ebner-stolz-testimonial></app-ebner-stolz-testimonial>

<app-ebner-stolz-how-it-works></app-ebner-stolz-how-it-works>

<!-- <app-ebner-stolz-app></app-ebner-stolz-app> -->

<app-ebner-stolz-cta (clicked)="showChatbot()"></app-ebner-stolz-cta>

<app-ebner-stolz-references></app-ebner-stolz-references>

<app-ebner-stolz-cta (clicked)="showChatbot()"></app-ebner-stolz-cta>

<sc-chatbot-overlay #chatbotOverlay [chat]="chat"></sc-chatbot-overlay>