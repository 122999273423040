import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-ebner-stolz-cta',
  templateUrl: './ebner-stolz-cta.component.html',
  styleUrls: ['./ebner-stolz-cta.component.css']
})
export class EbnerStolzCtaComponent implements OnInit {

  @Output()
  clicked = new EventEmitter<any>();
  
  constructor() { }

  ngOnInit(): void {
  }

}
