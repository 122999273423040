import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-facts',
  templateUrl: './product-facts.component.html',
  styleUrls: ['./product-facts.component.scss']
})
export class ProductFactsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
