<section>

	<div class="container">
  
		<div class="row align-items-center">
  
			<div class="col-12 col-md-6">
  
				<div class="row">
  
				  <div class="col offset-md-1 mb-lg-3">
					<h2 class="heading-xl heading-serif">Vielen Dank für Ihre Buchung, {{ fullName }}!</h2>					
					<a routerLink="/home"  class="btn btn-primary btn-round btn-lg mt-4">Zur Startseite</a>  
				  </div>
	
				</div> 
  
			</div>
  
			<div class="col-7 mt-n5 offset-4 col-md-3 offset-md-1 mt-md-0 position-relative">
				<picture>
					<img src="assets/svg/sc-check.svg" alt="" class="img-fluid w-100">
				</picture>
			</div>
  
		</div>
  
	</div>
  
  </section>