<app-home-hero></app-home-hero>
<app-home-references></app-home-references>
<app-product-facts></app-product-facts>
<!-- <app-home-usps></app-home-usps> -->
<!-- <app-product-candidate-experience></app-product-candidate-experience> -->
<app-home-how-it-works></app-home-how-it-works>
<app-home-benefits></app-home-benefits>
<!-- <app-home-company></app-home-company> -->
<app-pricing-guarantee></app-pricing-guarantee>
<app-home-faq></app-home-faq>
<app-job-check-cta></app-job-check-cta>
<!-- <app-contact-form></app-contact-form> -->
<!-- <app-home-blog></app-home-blog> -->