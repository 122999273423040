<div class="container-fluid">

  <div class="row align-items-center d-flex">
      
      <div class="col-auto">

        <!-- Avatar -->
        <div class="avatar">
          <img src="assets/icons/sc-icon.svg" alt="skillconomy Icon" class="avatar-img rounded-circle">
        </div>

      </div>
 
      <div class="col ms-n2">

       <h4 class="mb-0">skillconomy</h4>
        <p class="card-text smaller">
          <span class="text-success">●</span> Online
        </p>

      </div>


    </div>

</div>