<section class="container">
  <div class="row">
    <div class="col-12">
      <ul class="row list-unstyled client-logos mb-0">
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-sharenow.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-lindt.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-trumpf.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-netcologne.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-dz-bank.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-gardena.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-wago.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-webasto.svg"></li>
      </ul>
    </div>
  </div>
</section>