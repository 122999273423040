<div class="history-loader mt-3" *ngIf="showLoader">
    <sc-chatbot-messageloader></sc-chatbot-messageloader>
</div>

<div class="messages-container">
    <sc-chatbot-message-scroll-container (scrolledToOldest)="loadOlderMessages()" (scrolledToNewest)="onScrolledToNewest()">

        <ng-container *ngFor="let message of messages">
            
            <div *ngIf="message.Index == 1" class="text-center mt-2 smaller text-muted">
               Beginn des Chats
            </div>    

            <sc-chatbot-answer [isOwnMessage]="message.SenderId == chat.UserId" [message]="message" (sendMessage)="sendMessage($event)">
            </sc-chatbot-answer>
            
            <div *ngIf="message.Type == 'End' && message.Payload?.Data?.Button" class="text-center small mt-3 mb-2 light" small>
                <div [routerLink]="message.Payload.Data.Button.Route" [class]="message.Payload.Data.Button.CssClass">
                    {{ message.Payload.Data.Button.Text }}
                </div>
            </div>  
            
            <div *ngIf="message.Type == 'End'" class="text-center small mb-2 smaller text-muted">
                Ende des Chats
            </div>
            
        </ng-container>

    </sc-chatbot-message-scroll-container>
</div>


<div class="input-container" *ngIf="!isReadOnly">
    <!-- <div class="new-message-button-container" [class.show]="showNewMessageButton" (click)="scrollContainer?.scrollToNewest()">
        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-down" class="svg-inline--fa fa-chevron-down fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
            <path fill="currentColor" d="M441.9 167.3l-19.8-19.8c-4.7-4.7-12.3-4.7-17 0L224 328.2 42.9 147.5c-4.7-4.7-12.3-4.7-17 0L6.1 167.3c-4.7 4.7-4.7 12.3 0 17l209.4 209.4c4.7 4.7 12.3 4.7 17 0l209.4-209.4c4.7-4.7 4.7-12.3 0-17z"></path>
        </svg>
        <span>
            1
        </span>
    </div> -->
    <sc-chatbot-input [message]="newestMessage" [chat]="chat" (submit)="sendMessage($event)"></sc-chatbot-input>
</div>