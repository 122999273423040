import { Injectable } from '@angular/core';
import { posts } from 'src/assets/blog/listing';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  public posts: any = posts;

  public postsByCategory: any = {};

  public categories: any = [];

  constructor() {

    this.categories =
      [...new Set(this.posts.map(i => i.Category))]
        .sort((a, b) => {
          if (a < b) { return -1; }
          if (a > b) { return 1; }
          return 0;
        });

    this.posts.forEach(i => {
      if (!i.Category) return;
      if (!this.postsByCategory[i.Category]) this.postsByCategory[i.Category] = [];
      this.postsByCategory[i.Category].push(i);
    })
  }

  getPostsOfCategory(category) {
    return this.posts.filter(i => i.Category == category);
  }

  getPost(id) {
    return this.posts.find(i => i.Uri == id);
  }
}
