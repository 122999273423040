<section class="sc-hero">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-md-10 col-lg-12">
            <h1 class="heading-xl heading-serif">
              Impressum
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-md-6 mb-5 mb-md-0 ">
        <h3>Angaben gemäß § 5 TMG:</h3>
        <p class="large"> skillconomy GmbH<br> Werkstättenstraße 18<br> 51379 Leverkusen<br> Deutschland</p>
      </div>
      <div class="col-12 col-lg-6 col-md-5 mb-md-0 offset-lg-0 offset-md-1 ">
        <h3>Kontakt</h3>
        <p class="large"> +49 2171 - 77 67 67 6<br> jonas@skillconomy.com
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-5 col-md-8">
        <h3>Geschäftsführer:</h3>
        <p class="large">Lars Branscheid, Marc Branscheid, <br>Jonas Krömer</p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-5 col-md-8">
        <h3>Verantwortlich für den Inhalt nach §55 Abs. 2 RStV:</h3>
        <p class="large">Lars Branscheid</p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-5 col-md-8">
        <h3>Handelsregister</h3>
        <p class="large">HRB 15517</p>
      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-5 col-md-8">
        <h3>Umsatzsteuer-ID</h3>
        <p class="large">DE301461523</p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Haftungsbeschränkung</h3>
        <p class="large">Die Webseite wurde mit größtmöglicher Sorgfalt erstellt. Der Anbieter dieser Webseite übernimmt dennoch keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der bereitgestellten Inhalte und Informationen. Die Nutzung der Webseiteninhalte erfolgt auf eigene Gefahr. Allein durch die Nutzung der Website kommt keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande.</p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Verlinkungen</h3>
        <p class="large">Die Webseite enthält Verlinkungen zu anderen Webseiten ("externe Links"). Diese Webseiten unterliegen der Haftung der jeweiligen Seitenbetreiber. Bei Verknüpfung der externen Links waren keine Rechtsverstöße ersichtlich. Auf die aktuelle und künftige Gestaltung der verlinkten Seiten hat der Anbieter keinen Einfluss. Die permanente überprüfung der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar. Bei Bekanntwerden von Rechtsverstößen werden die betroffenen externen Links unverzüglich gelöscht.</p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Urheberrecht/Leistungsschutzrecht</h3>
        <p class="large">Die auf dieser Webseite durch den Anbieter veröffentlichten Inhalte unterliegen dem deutschen Urheberrecht und Leistungsschutzrecht. Alle vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies gilt vor allem für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Dabei sind Inhalte und Rechte Dritter als solche gekennzeichnet. Das unerlaubte Kopieren der Webseiteninhalte oder der kompletten Webseite ist nicht gestattet und strafbar. Lediglich die Herstellung von Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.</p>
        <p class="large">Diese Website darf ohne schriftliche Erlaubnis nicht durch Dritte in Frames oder iFrames dargestellt werden. </p>
      </div>
    </div>
  </div>
</section>
