import { Component, OnInit } from '@angular/core';
import { ScApiService } from '@sc-ui';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  unsubscribed = false;

  constructor(private route : ActivatedRoute, private api : ScApiService) { }

  ngOnInit() {

    this.route.params.subscribe((params : any) => {
      if (!params.secret) return;
      this.api.post('newsletter/unsubscribe/' + params.secret, null).subscribe(d => {
        this.unsubscribed = true;
      });
    });    
    
  }

}
