import { Component, OnInit, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { scChatbotAnimation } from '../animations';


@Component({
  selector: 'sc-chatbot-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
  animations: [ scChatbotAnimation.message ]
})
export class ScChatbotAnswerComponent implements OnInit {

  @Input()
  message : any;

  @Input() 
  avatar : string;

  @Input()
  isOwnMessage = false;

  @Output()
  sendMessage = new EventEmitter<any>();

  public _visible;
  
  constructor() { }
  
  ngOnInit() {
    this._visible = this.message?.History ? 'visible-instant' : 'visible';
  }

  getMessageJson() {
    return JSON.stringify(this.message);
  }

  resendMessage()
  {
    this.sendMessage.next(this.message);
  }

}
