import { Component } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'sc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

}
