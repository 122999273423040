import { Component, AfterViewInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';


@Component({
  selector: 'app-ticker-span',
  templateUrl: './ticker-span.component.html',
  styleUrls: ['./ticker-span.component.scss']
})
export class TickerSpanComponent implements AfterViewInit {
  @ViewChild('tickerText', { static: false }) tickerText!: ElementRef;
  @ViewChild('tickerContainer', { static: false }) tickerContainer!: ElementRef;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const tickerTextElement = this.tickerText.nativeElement;
    const tickerContainerElement = this.tickerContainer.nativeElement;

    // Get the container's width
    const containerWidth = tickerContainerElement.offsetWidth;

    // Check if the text overflows the container
    if (tickerTextElement.offsetWidth > containerWidth) {
      // Remove no-animation class if present
      this.renderer.removeClass(tickerTextElement, 'no-animation');

      // Create a new keyframes animation with dynamic width values
      const tickerKeyframes = `
        @keyframes ticker {
          0% {
            transform: translateX(${containerWidth}px);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      `;

      // Append the new keyframes to the document
      const styleSheet = this.renderer.createElement('style');
      styleSheet.type = 'text/css';
      styleSheet.innerHTML = tickerKeyframes;
      this.renderer.appendChild(document.head, styleSheet);

      // Apply the animation to the ticker text
      this.renderer.setStyle(tickerTextElement, 'animation', 'ticker 10s linear infinite');
    } else {
      // Add no-animation class if text does not overflow
      this.renderer.addClass(tickerTextElement, 'no-animation');
    }
  }
}
