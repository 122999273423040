<section class="sc-hero">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-10 col-lg-6">
        <h1 class="heading-xl heading-serif">
          Datenschutz
        </h1>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Datenschutzerklärung</h3>
        <p class="large">
          Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personen­bezogenen Daten
          (nachfolgend kurz »Daten«) innerhalb unseres Online­an­gebotes und der mit ihm verbundenen Web­seiten, Funktionen und Inhalte
          sowie externen Onlinepräsenzen, wie z.B. unser Social Media Profile auf. (nachfolgend gemeinsam bezeichnet als »Onlineangebot«).
          Im Hinblick auf die verwendeten Begrifflichkeiten, wie z. B. »personenbezogene Daten« oder deren »Verarbeitung« verweisen
          wir auf die Definitionen im Art. 4 der Datenschutz­grund­verordnung (DSGVO).
        </p>
      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-4 col-md-8">
        <h3>Verantwortlicher</h3>
		
        <p class="large">
          skillconomy GmbH<br>
          Werkstättenstraße 18<br>
          51379 Leverkusen<br>
          Deutschland
        </p>

      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Vertreten durch</h3>

        <p class="large">
          Lars Branscheid
        </p>

      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Allgemeine Hinweise zur Datenverarbeitung</h3>

        <p class="large">
          Wir verarbeiten personenbezogene Daten unserer Nutzer grundsätzlich nur, soweit dies zur Bereitstellung einer
          funktionsfähigen Website sowie unserer Inhalte und Leistungen erforderlich ist. Die Verarbeitung personenbezogener
          Daten unserer Nutzer erfolgt regelmäßig nur nach Einwilligung des Nutzers. Eine Ausnahme gilt in solchen Fällen,
          in denen eine vorherige Einholung einer Einwilligung aus tatsächlichen Gründen nicht möglich ist und die
          Verarbeitung der Daten durch gesetzliche Vorschriften gestattet ist. Die skillconomy GmbH erhebt, verarbeitet
          und nutzt personenbezogene Daten grundsätzlich nur in eigener Verantwortung und nicht im Auftrag Dritter, insbesondere
          auch dann nicht, wenn Nutzer über externe soziale Netzwerke im Zusammenhang mit einem konkreten Stellenangebot
          eines Dritten angesprochen werden.
        </p>

      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Weitergabe von Daten</h3>
        <p class="large">
          Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken
          findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn: Sie Ihre nach Art. 6
          Abs.1 lit.a DSGVO ausdrückliche Einwilligung dazu erteilt haben, die Weitergabe nach Art.6 Abs.1 lit.f
          DSGVO erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
          Interesse an der Nichtweitergabe Ihrer Daten haben, für den Fall, dass für die Weitergabe nach Art. 6
          Abs.1 lit.c DSGVO eine gesetzliche Verpflichtung besteht, sowie dies gesetzlich zulässig und nach
          Art. 6 Abs.1 lit.b DSGVO für die Abwicklung von Vertragsverhältnissen mit Ihnen erforderlich ist.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-12">
        <h3>Betroffenenrechte</h3>

        <p class="large">
          Ihnen stehen die nachfolgenden Betroffenenrechte zu:<br>
          a. Auskunftsrecht<br>
          Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob Sie betreffende personenbezogene Daten verarbeitet werden.<br>
          b. Berichtigung/Löschung/Einschränkung der Verarbeitung<br>
          Des Weiteren haben Sie das Recht, von uns zu verlangen, dass <br>
          • Sie betreffende unrichtige personenbezogene Daten unverzüglich berichtigt werden (Recht auf Berichtigung); <br>
          • Sie betreffende personenbezogene Daten unverzüglich gelöscht werden (Recht auf Löschung) und <br>
          • die Verarbeitung eingeschränkt wird (Recht auf Einschränken der Verarbeitung).
          <br>
          c. Recht auf Datenübertragbarkeit<br>
          Sie haben das Recht, Sie betreffende personenbezogene Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten und diese Daten einem anderen Verantwortlichen zu übermitteln.<br>
          d. Widerrufsrecht<br>
          Sie haben das Recht, Ihre Einwilligung jederzeit zu widerrufen. Durch den Widerruf der Einwilligung wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.<br>
          e. Widerspruchsrecht<br>
          Ist die Verarbeitung Sie betreffender personenbezogener Daten für die Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt (Art. 6 Abs. 1 Buchst. e) DSGVO) oder zur Wahrung unserer berechtigten Interessen (Art. 6 Abs. 1 Buchst. f) DSGVO) erforderlich, steht Ihnen ein Widerspruchsrecht zu.<br>
          f. Beschwerderecht<br>
          Sind Sie der Ansicht, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die DSGVO verstößt, haben Sie unbeschadet anderweitiger Rechtsbehelfe das Recht auf Beschwerde bei einer Aufsichtsbehörde<br>
        </p>

      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Datenlöschung und Speicherdauer</h3>
        <p class="large">
          Die personenbezogenen Daten der betroffenen Person werden gelöscht oder gesperrt, sobald der Zweck der Speicherung entfällt. Eine
          Speicherung kann darüber hinaus erfolgen, wenn dies durch den europäischen oder nationalen Gesetzgeber in unionsrechtlichen
          Verordnungen, Gesetzen oder sonstigen Vorschriften, denen der Verantwortliche unterliegt, vorgesehen wurde. Eine Sperrung
          oder Löschung der Daten erfolgt auch dann, wenn eine durch die genannten Normen vorgeschriebene Speicherfrist abläuft,
          es sei denn, dass eine Erforderlichkeit zur weiteren Speicherung der Daten für einen Vertragsabschluss oder eine
          Vertragserfüllung besteht.
        </p>
      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>1. Erfassung und Verarbeitung personenbezogener Daten im Zusammenhang mit dem Besuch unserer Online-Stellenangebote</h3>
        <h3>Kontaktaufnahme in beruflichen Netzwerken (bspw. Xing und LinkedIn)</h3>
        <p class="large">
          Wir kontaktieren Personen in beruflichen Netzwerken, sofern diese den Empfang von Nachrichten innerhalb
          des Netzwerks erlauben. Um eine effiziente Kommunikation zu gewährleisten (kein mehrmaliger Versand
          von Nachrichten, etc.), verarbeiten wir die öffentlich verfügbaren Informationen wie Vor- und Nachname,
          Arbeitsort, Berufsausbildung etc. und speichern Informationen über die Kontaktaufnahme (Datum, Zweck,
          etc.). Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit.f DSGVO sowie Art. 9 Abs.2
          lit.e DSGVO. Unser berechtigtes Interesse folgt aus oben genannten Zwecken zur Datenerhebung. Die Daten
          werden gelöscht, sobald sie für die Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind.
        </p>
      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Interessensbekundung an einem Stellenangebot</h3>
        <p class="large">
          Um Kontakt mit dem Stellenverantwortlichen eines Unternehmens aufzunehmen, bieten wir Ihnen die Möglichkeit,
          Ihre Kontaktdaten über ein auf der Website bereitgestelltes Formular an uns zu übermitteln. Dabei ist die
          Angabe einer gültigen E-Mail-Adresse und/oder Telefonnummer erforderlich, damit wir zuordnen können, von
          wem die Kontaktaufnahme stammt. Ihre Kontaktdaten sowie Auszüge aus Ihren öffentlichen Profildaten
          (bspw. Jobtitel, Ausbildung, etc.) stellen wir den zuständigen Personen des suchenden Unternehmens
          (in der Regel Mitarbeiter der Personalabteilung und/oder Fachabteilung) in unserem passwortgeschützten
          Online-Portal zur Verfügung. Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns und dem suchenden
          Unternehmen erfolgt nach Art. 6 Abs.1 lit.a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung. Die
          für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Abschluss der
          Kontaktaufnahme zwischen Ihnen und dem Unternehmen (bspw. durch Ablehnung eines Vorstellungsgesprächs oder
          Zustandekommen eines Arbeitsvertrags) automatisch gelöscht. Weitere personenbezogene Daten, die Sie im
          weiteren Bewerbungsprozess direkt an das suchende Unternehmen übermitteln, unterliegen den jeweiligen
          Datenschutzbestimmungen des suchenden Unternehmens.
        </p>

      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Interessensbekundung an weiteren Stellenangeboten</h3>
        <p class="large">
          Um Ihnen passgenaue Stellenangebote zusenden zu können, bieten wir Ihnen die Möglichkeit, über unsere
          Microsites nähere Angaben zu Ihren Präferenzen und Suchkriterien zu machen. Dabei ist die Angabe einer
          gültigen E-Mail-Adresse und/oder Telefonnummer erforderlich, damit wir zuordnen können, von wem die
          Kontaktaufnahme stammt und um Sie bei entsprechenden Angeboten kontaktieren zu können. Weitere Angaben
          können freiwillig getätigt werden. Darüber hinaus verarbeiten wir Ihre öffentlichen Profildaten, um
          Ihnen auf Ihre Qualifikation zugeschnittene Angebote unterbreiten zu können. Die Datenverarbeitung
          zum Zwecke der Kommunikation mit uns erfolgt nach Art. 6 Abs.1 lit.a DSGVO auf Grundlage Ihrer
          freiwillig erteilten Einwilligung. Die Daten werden gelöscht, sobald sie für die Erreichung des
          Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die Speicherung der von uns erhobenen personenbezogenen
          Daten sowie die Einwilligung zur Kontaktaufnahme können Sie jederzeit schriftlich unter datenschutz@skillconomy.com widerrufen.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>2. Erfassung und Verarbeitung personenbezogener Daten im Zusammenhang mit dem Besuch unserer Website</h3>
        <h3>Nutzungsdaten</h3>
        <p class="large">
          Beim Aufrufen unserer Webseite werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser
          automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden
          temporär in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun
          erfasst und bis zur automatisierten Löschung gespeichert: Name der abgerufenen Datei, Datum und Uhrzeit
          des Abrufs, übertragene Datenmenge , Meldung, ob der Abruf erfolgreich war, Beschreibung des Typs des
          verwendeten Webbrowsers, verwendetes Betriebssystem, die zuvor besuchte Seite, Provider sowie Ihre IP-Adresse.
        </p>
        <p class="large">
          Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet: Gewährleistung eines reibungslosen
          Verbindungsaufbaus der Website, Gewährleistung einer komfortablen Nutzung unserer Website, Auswertung der
          Systemsicherheit und -stabilität sowie zu weiteren administrativen Zwecken.
        </p>
        <p class="large">
          Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs.1 lit.f DSGVO. Unser berechtigtes Interesse
          folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten
          zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen. Die Daten werden gelöscht, sobald sie für die
          Erreichung des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung der Daten
          zur Bereitstellung der Website ist dies der Fall, wenn die jeweilige Sitzung beendet ist. Im Falle der
          Speicherung der Daten in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine darüberhinausgehende
          Speicherung ist möglich. In diesem Fall werden die IP-Adressen der Nutzer gelöscht oder verfremdet, sodass
          eine Zuordnung des aufrufenden Clients nicht mehr möglich ist.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Cookies</h3>
        <p class="large">
          Wir setzen in einigen Bereichen unserer Webseite sogenannte Cookies ein. Durch solche Dateielemente kann
          Ihr Computer als technische Einheit während Ihres Besuchs auf dieser Webseite identifiziert werden, um
          Ihnen die Verwendung unseres Angebotes – auch bei Wiederholungsbesuchen – zu erleichtern. Sie haben aber in der
          Regel die Möglichkeit, Ihren Internetbrowser so einzustellen, dass Sie über das Auftreten von Cookies informiert
          werden, so dass Sie diese zulassen oder ausschließen, beziehungsweise bereits vorhandene Cookies löschen können.
          Bitte verwenden Sie die Hilfefunktion Ihres Internetbrowsers, um Informationen zu der Änderung dieser
          Einstellungen zu erlangen. Wir weisen darauf hin, dass einzelne Funktionen unserer Webseite möglicherweise
          nicht funktionieren, wenn Sie die Verwendung von Cookies deaktiviert haben. Cookies erlauben nicht, dass ein
          Server private Daten von Ihrem Computer oder die von einem anderen Server abgelegten Daten lesen kann. Sie
          richten auf Ihrem Rechner keinen Schaden an und enthalten keine Viren. Wir stützen den Einsatz von Cookies
          auf Art. 6 Abs. 1 lit.f DSGVO: die Verarbeitung erfolgt zur Verbesserung der Funktionsweise unserer
          Webseite. Sie ist daher zur Wahrung unserer berechtigten Interessen erforderlich.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Webanalysedienste</h3>
        <p class="large">
          Wir benutzen Google Analytics, einen Webanalysedienst der Google Inc., 1600 Amphitheatre Parkway, Mountain View,
          CA 94043, USA. Google Analytics verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden
          und die eine Analyse der Benutzung der Webseite durch sie ermöglichen. Die durch den Cookie erzeugten
          Informationen über Ihre Benutzung dieser Webseite werden in der Regel an einen Server von Google in den USA
          übertragen und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre
          IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
          des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse
          an einen Server von Google in den USA übertragen und dort gekürzt. In unserem Auftrag wird Google diese Informationen
          benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseitenaktivitäten zusammenzustellen und
          um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen uns gegenüber zu erbringen.
          Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
          zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
          verhindern; wir weisen jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
          Webseite vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
          und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser
          Daten durch Google verhindern, indem Sie das unter dem folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de)
          verfügbare Browser-Plugin herunterladen und installieren. Wir weisen darauf hin, dass auf dieser Webseite Google Analytics
          um den Code „gat._anonymizeIp();“ erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking)
          zu gewährleisten. Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter www.google.com/analytics/terms/ de.html
          bzw. unter www.google.com/intl/de/analytics/privacyoverview.html. Wir stützen den Einsatz des vorgenannten Analyse-Tools auf
          Art. 6 Abs. 1 Buchst. f) DSGVO: die Verarbeitung erfolgt zur Analyse des Nutzungsverhaltens und ist daher zur Wahrung unserer
          berechtigten Interessen erforderlich.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Social Plug-ins</h3>
        <p class="large">
          Wir setzen bewusst keine Social Plugins, bspw. des sozialen Netzwerks Facebook ein.
        </p>
      </div>
    </div>

  </div>
</section>

<section class="pt-7">
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Newsletter</h3>
        <p class="large">
          Sofern Sie nach Art. 6 Abs.1 lit.a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre
          E-Mail-Adresse dafür, Ihnen regelmäßig unseren Newsletter zu übersenden. Für den Empfang des Newsletters
          ist die Angabe einer E-Mail-Adresse ausreichend. Die Abmeldung ist jederzeit möglich, zum Beispiel über
          einen Link am Ende eines jeden Newsletters. Alternativ können Sie Ihren Abmeldewunsch gerne auch jederzeit
          datenschutz@skillconomy.com per E-Mail senden. Die Daten werden gelöscht, sobald sie für die Erreichung
          des Zweckes ihrer Erhebung nicht mehr erforderlich sind. Die E-Mail-Adresse des Nutzers wird demnach
          solange gespeichert, wie das Abonnement des Newsletters aktiv ist.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <h3>Kontaktformular</h3>
        <p class="large">
          Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes
          Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir
          wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig
          getätigt werden. Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs.1
          lit.a DSGVO auf Grundlage Ihrer freiwillig erteilten Einwilligung. Die für die Benutzung des Kontaktformulars
          von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage automatisch
          gelöscht. Ihre Daten werden nicht an Dritte weitergegeben.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="pt-7">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8 col-md-10">
        <p class="large">Stand: Juli 2022</p>
        <p class="large">Wir behalten uns vor, diese Erklärung bei Bedarf zu aktualisieren oder zu ergänzen. Änderungen werden wir an dieser Stelle veröffentlichen.</p>
      </div>
    </div>
  </div>
</section>
