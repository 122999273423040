import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlogService } from 'src/services/blog.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  posts: any = [];

  categories: any = [];

  category: any = '';

  page = 1;

  totalPages = 1;

  pageSize = 1;

  constructor(private blogService: BlogService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.categories = this.blogService.categories;
    this.loadPosts();

    this.route.queryParams.subscribe(params => {
      this.category = params.category;
      this.page = params.page ? parseInt(params.page) : 1;
      this.loadPosts();
    });
  }

  loadPosts() {
    if (this.category) {
      this.posts = this.getPage(this.blogService.postsByCategory[this.category]);
      this.totalPages = this.getTotalPages(this.blogService.postsByCategory[this.category]);
    }
    else {
      this.posts = this.getPage(this.blogService.posts);
      this.totalPages = this.getTotalPages(this.blogService.posts);
    }
  }

  getPage(posts) {
    return posts.slice((this.page - 1) * this.pageSize, (this.page) * this.pageSize);
  }

  getTotalPages(posts) {
    return Math.ceil(posts.length / this.pageSize) - 1;
  }

  selectCategory(category) {
    this.router.navigateByUrl('/blog?category=' + encodeURIComponent(category));
  }

  nextPage() {
    if (this.page < this.totalPages) {
      var uri = '/blog?page=' + (this.page + 1);
      if (this.category) uri += '&category=' + this.category;
      this.router.navigateByUrl(uri);
    }
  }

  previousPage() {
    if (this.page > 0) {
      var uri = '/blog?page=' + (this.page - 1);
      if (this.category) uri += '&category=' + this.category;
      this.router.navigateByUrl(uri);
    }
  }

  goToPage(page) {
    var uri = '/blog?page=' + (page);
    if (this.category) uri += '&category=' + this.category;
    this.router.navigateByUrl(uri);
  }

  showAll() {
    this.router.navigateByUrl('/blog');
  }

}
