<!-- <section class="sc-references-teaser container">
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
      <h3 class="display-2 quote">
        »Wir sind sehr zufrieden mit der professionellen und schnellen
        Umsetzung der Projekte und der guten Qualität der vor&shy;geschla&shy;genen
        Kandidat:innen.«
      </h3>
      <div class="row mt-6 align-items-center">

        <div class="col-auto">
          <img class="avatar img-fluid" src="./assets/img/noesner.png" />
        </div>

        <div class="col">
          <h5 class="mb-0 d-block">Christiane Nösner</h5>
          <p class="mb-0">Recruiterin bei Union Investment</p>
        </div>
      </div>
    </div>
  </div>
</section> -->

<section class="container">
  <div class="row">
    <div class="col-12">
      <ul class="row list-unstyled client-logos mb-0">
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-sharenow.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-lindt.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-trumpf.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-netcologne.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-dz-bank.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-gardena.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-wago.svg"></li>
        <li class="col-6 col-md-3 mb-5"><img width="181" height="105" class="img-fluid" src="assets/svg/client-logos/logo-rsm-ebner-stolz.svg"></li>
      </ul>
    </div>
  </div>
</section>
