import { Component, OnInit, OnDestroy, ViewChild, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ScApiService } from '@sc-ui';
import { isPlatformBrowser } from '@angular/common';
import { ChatsService, ScChatbotOverlayComponent } from '@sc-ui-chatbot';


@Component({
  selector: 'app-ebner-stolz',
  templateUrl: './ebner-stolz.component.html',
  styleUrls: ['./ebner-stolz.component.scss']
})
export class EbnerCandidateTrackComponent implements OnInit {

  chat: any;

  variant: 'business' | 'it' = 'business';

  secret: string;

  chatModel = {};

  @ViewChild(ScChatbotOverlayComponent)
  chatOverlay: ScChatbotOverlayComponent;

  constructor(
    private route: ActivatedRoute,
    private api: ScApiService,
    private chats: ChatsService,
    @Inject(PLATFORM_ID) private platformId: string,) { }


  ngOnInit() {

    this.route.data.subscribe(d => {
      this.variant = d.variant;
      console.log(this.variant);
    })

    this.route.params.subscribe(params => {
      if (params["secret"]) {
        this.secret = params['secret'];
        this.api.login('', this.secret).subscribe(() => {
          this.api.post(`candidates/${this.secret}/trace`, { TraceId: 'ebner_track_landingpage_visit' }).subscribe();
          this.loadChat();
        });
      }
    });
  }

  loadChat() {

    var isBrowser = isPlatformBrowser(this.platformId);
    if (!isBrowser) return;

    this.chats.getChat('chats/ebner-stolz-track').subscribe(c => {
      this.chat = c;
      setTimeout(() => {
        this.chatOverlay.trigger.slideIn();
        this.chatOverlay.trigger.badge = 1;
        this.chatOverlay.trigger.startPulse();
      }, 4000);
    });
  }

  showChatbot() {
    this.chatOverlay.show();
  }
}