
<div class="job-check-card card card-lg p-2 p-sm-4 px-lg-5 px-xl-6" scAnchorTarget="book">

  <div class="card-body">

    <div class="row d-lg-none justify-content-center mb-5">
      <div class="col-10 col-sm-9 col-md-8">
        <img src="assets/img/jonas-kroemer-termin.png" alt="skillconomy Illu Bot" width="655" height="652" class="img-fluid bot-illu">
      </div>
    </div>

    <div class="row justify-lg-content-center align-items-center">
      
      <div class="col-12 col-lg-6">
      
        <h2 class="heading-serif heading-lg mb-4">Buche eine Demo</h2>

        <p class="lead">
          Du kannst die KI anhand einer konkreten Stelle von dir live erleben. Statt Sales-Bla-Bla ein entspanntes
          Kennenlernen mit Mehrwert. Buche hier einfach deinen Termin!
        </p>

        <a class="btn btn-primary btn-lg btn-round mt-4" href="https://calendly.com/skillconomy-kennenlernen/buchen?utm_source=website&month=2024-09" target="_blank">Jetzt buchen</a>

      </div>

      <div class="d-none d-lg-flex col-5 offset-7 col-md-4 offset-md-0 mt-n5 mt-md-0 mt-lg-0 offset-lg-1 col-lg-5 px-lg-4">
        <img src="assets/img/jonas-kroemer-termin.png" alt="skillconomy Illu Bot" width="655" height="652" class="img-fluid bot-illu">
      </div>

    </div>

  </div>

</div>
