import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'sc-chatbot-answer-text',
  templateUrl: './answer-text.component.html',
  styleUrls: ['./answer-text.component.scss']
})
export class ScChatbotAnswerTextComponent implements OnChanges {

  safeHtml : SafeHtml;

  @Input()
  message : any;
  
  constructor(private sanitizer : DomSanitizer) { }

  ngOnChanges()
  {
    this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this.message.Payload.Body);
  }
}
