
import {trigger, stagger, animate, style, group, sequence, query, transition} from '@angular/animations';

export const routerTransition = trigger('routerTransition', [

  /*
  transition('_welcome => _ai', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }),
    group([
      query(':leave .btn', [
        style({ color: '*', transform: 'scale(1)' }),
        animate('0.1s 0s ease-in-out', style({ color: 'rgba(255,255,255,0)' })),
        animate('0.8s 0.1s ease-in-out', style({ transform: 'scale(50)' })),
      ], { optional: true }),
      query(':leave', [
        style({ opacity: 1, display: 'block' }),
        animate('0.5s 1s ease-in-out', style({ opacity: 0 })),
        animate('0.8s 0.1s linear', style({ opacity: 0.5, })),
      ], { optional: true }),
      query(':enter', [
        style({ opacity: 0 }),
        animate('0.1s 0s ease-in-out', style({ opacity: 1 }))
      ], { optional: true }),
    ]),

  ]),
*/

  transition('_ai => _reporting, _welcome => _ai', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }),
    group([
      query(':enter', [
        style({ transform: 'translateY(100%)', 'z-index': 1000, height: '100vh'  }),
        animate('0.8s ease-in-out', style({ transform: 'translateY(0%)' }))
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateY(0%) scale(1)', opacity: 1 }),
        animate('0.8s ease-in-out', style({ transform: 'translateY(-100%)', opacity: 0 }))
      ], { optional: true }),
    ])
  ]),

  transition('* <=> *', [
    query(':enter, :leave', style({ position: 'fixed', width: '100%' })
      , { optional: true }),
    group([
      query(':enter', [
        style({ opacity: 0 }),
        animate('0.5s ease-in-out', style({ opacity: 1 }))
      ], { optional: true }),
      query(':leave', [
        style({ opacity: 1 }),
        animate('0.5s ease-in-out', style({ opacity: 0 }))
      ], { optional: true }),
    ])
  ])
]);
