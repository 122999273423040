import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[ScAnimateHeight]'
})
export class AnimateHeightDirective {

  constructor(private hostRef: ElementRef) { 
    hostRef.nativeElement.style.transition = "height 500ms";
    hostRef.nativeElement.style.overflowY = "hidden";
  }

  animateToContentHeight(minHeight = 0) {
    var containerEl = this.hostRef.nativeElement;
    var contentEl = containerEl.firstElementChild; 
    var fullHeight = contentEl ? contentEl.scrollHeight + 30 : minHeight;
    setTimeout(() => containerEl.style.height = fullHeight + 'px', 1);
  }

  animateToHeight(height : number)
  {
    var containerEl = this.hostRef.nativeElement;
    setTimeout(() => containerEl.style.height = height + 'px', 1);
  }

}
