import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { global } from '../global';
import { BodyService } from '@sc-ui';

@Injectable({
  providedIn: 'root'
})
export class MetaTagsService {

  constructor(
    private meta: Meta, 
    private titleService: Title,
    private bodyService: BodyService
  ) { }

  addMetaTags(model: any) {

    console.log("model", model.bodyClasses);

    if (model.title) {
      this.titleService.setTitle( model.title );
      this.meta.updateTag({ name: "title", content: model.title });
      this.meta.updateTag({ name: "og:title", content: model.title });
      this.meta.updateTag({ name: "twitter:title", content: model.title });
    }

    if (model.description) {
      this.meta.updateTag({ name: "description", content: model.description });
      this.meta.updateTag({ name: "og:description", content: model.description });
      this.meta.updateTag({ name: "twitter:description", content: model.description });
    }

    if (model.sharingImageUri) {
      this.meta.updateTag({ name: "og:image", content: model.sharingImageUri });
      this.meta.updateTag({ name: "twitter:image", content: model.sharingImageUri });
    }

    if (model.keywords) {
      this.meta.updateTag({ name: "keywords", content: model.keywords});
    }

    this.bodyService.clearClasses(); 
    if(model.bodyClasses) {
      model.bodyClasses.forEach(c => this.bodyService.addClass(c));
    } 

  }
}
