import { Component } from '@angular/core';


@Component({
  selector: 'app-home-how-it-works',
  templateUrl: './home-how-it-works.component.html',
  styleUrls: ['./home-how-it-works.component.scss']
})

export class HomeHowItWorksComponent {


}
