import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LinkedInTrackingService {

  isBrowser = false;

  isInitialized = false;  

  track() { 
    window['lintrk']('track');
  }

  init() {

    if (this.isInitialized) return;
    
    const img = new Image();
    img.src = 'https://px.ads.linkedin.com/collect/?pid=6230345&fmt=gif';

    var _linkedin_partner_id = "6230345";

    window['_linkedin_data_partner_ids'] = window['_linkedin_data_partner_ids'] || [];
    window['_linkedin_data_partner_ids'].push(_linkedin_partner_id);

    (function (l) {
      if (!l) {
        window['lintrk'] = function (a, b) {
          window['lintrk'].q.push([a, b])
        };
        window['lintrk'].q = []
      }
      var s = document.getElementsByTagName("script")[0];
      var b = document.createElement("script");
      b.type = "text/javascript";
      b.async = true;
      b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
      s.parentNode.insertBefore(b, s);
    })(window['lintrk']);

    setTimeout(() => { this.isInitialized = true; }, 1);
  }
}