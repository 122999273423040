import { Component, OnInit, QueryList, AfterViewInit, ContentChildren, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { ModalWizardPageComponent } from '../modal-wizard-page/modal-wizard-page.component';
import { ScuiModalComponent } from '../modal/modal.component';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'sc-modal-wizard',
  templateUrl: './modal-wizard.component.html',
  styleUrls: ['./modal-wizard.component.scss']
})
export class ModalWizardComponent implements OnInit, AfterViewInit {

  @Input()
  size: 'sm' | 'lg' | 'xl' = null;

  @ViewChild('modal')
  modal : ScuiModalComponent;

  @ContentChildren(ModalWizardPageComponent, { descendants: true })
  private _pages: QueryList<ModalWizardPageComponent>;

  @ContentChildren(ModalHeaderComponent, { descendants: true }) 
  _headers :  QueryList<ModalHeaderComponent>; 

  currentPage: ModalWizardPageComponent;

  ngOnInit() { }

  ngAfterViewInit() {
    
    if (!this._pages.some(p => p.definesHeight))
    {
      setTimeout(() => { this._pages.first.definesHeight = true; });
    }

    this._pages.forEach(page => {
      if (page == this._pages.first) {
        this.currentPage = page;
        page.show();
      }
      else {
        page.hide();
      }
    });

    this._headers.forEach(h => {
      h.closeClick.subscribe(() => this.dismiss());
    });
  }

  show(targetPage: ModalWizardPageComponent = null) {

    if (targetPage)
    {
      this._pages.forEach(page => {
        if (page == targetPage) {
          this.currentPage = page;
          page.show();
        }
        else {
          page.hide();
        }
      });
    }

    return this.modal.show();
  }

  dismiss() {
    this.modal.dismiss();
  }

  complete(args)
  {
    this.modal.complete(args);
  }

  navigateTo(targetPage: ModalWizardPageComponent, direction: 'rightToLeft' | 'leftToRight' | 'auto' | 'none' = 'auto') {
    console.log(targetPage);

    var isCurrentPageOut = false;
    var isTargetPageIn = false;

    this._pages.forEach(page => {

      if (page == targetPage) {
        if (direction == 'none')
        {
          page.show();
        }
        else if (direction == 'rightToLeft' || (direction == 'auto' && isCurrentPageOut)) {
          page.slideInFromRight();
        }
        else {
          page.slideInFromLeft();
        }

        isTargetPageIn = true;
      }
      else if (page == this.currentPage)
      {
        if (direction == 'none')
        {
          page.hide();
        }
        else if (direction == 'rightToLeft' || (direction == 'auto' && !isTargetPageIn)) {
          page.slideOutToLeft();
        }
        else {
          page.slideOutToRight();
        }
        
        isCurrentPageOut = true;
      }
    });

    this.currentPage = targetPage;
  }

}
