<section>
    <div class="container">

        <div class="row align-items-center">
            <div class="col-12 col-sm-10 col-md-8 col-lg-7">
                <h1 class="heading-xl heading-serif">Konkrete Benefits</h1>
                <p class="larger">
                    Erhalte einen exklusiven Zugang zu RSM Ebner Stolz. Jobs, Insider-Tipps und Kontakte, die du nirgendwo sonst bekommen kannst.
                </p>
            </div>

            <div class="d-none d-md-block col-3 offset-1 col-lg-4 offset-lg-1">
                <img class="img-fluid illu" src="assets/svg/sc-bot-magnifying-glass.svg" />
            </div>
        </div>

        <app-ebner-stolz-how-it-works-step number="1" headline="Jobs, Praktika & Co." image="assets/img/ebner-stolz/Bild1.jpg">
            Passgenau zu deinen Karrierezielen: Der Track eröffnet dir konkrete Möglichkeiten für deinen Einstieg bei RSM Ebner Stolz – abseits der normalen Bewerbungsprozesse.
        </app-ebner-stolz-how-it-works-step>
		<app-ebner-stolz-how-it-works-step number="2" headline="Exklusive Events" image="assets/img/ebner-stolz/Bild2.jpg">
            Du erhältst Zugang zu Events, die nur für dich als Track-Teilnehmer geöffnet sind sowie eine bevorzugte Behandlung bei Career Days, Messen & Co.
        </app-ebner-stolz-how-it-works-step>
		<app-ebner-stolz-how-it-works-step number="3" headline="Insider-Tipps" image="assets/img/ebner-stolz/Bild3.jpg">
            Einblicke, die sonst nur Mitarbeitende bekommen. Im Track schaust du hinter die Kulissen und erhältst relevante Insider-Tipps direkt von deiner persönlichen Ansprechperson.
        </app-ebner-stolz-how-it-works-step>
    </div>

</section>