import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ebner-stolz-references',
  templateUrl: './ebner-stolz-references.component.html',
  styleUrls: ['./ebner-stolz-references.component.scss']
})
export class EbnerStolzReferencesComponent implements OnInit {

  items: any = [
    {
      name: "SHARENOW",
      logo: "logo-sharenow"
    },
    {
      name: "Lindt & Sprüngli",
      logo: "logo-lindt"
    },
    {
      name: "TRUMPF GmbH + Co. KG",
      logo: "logo-trumpf"
    },
    {
      name: "NetCologne",
      logo: "logo-netcologne"
    },
    {
      name: "DZ Bank",
      logo: "logo-dz-bank"
    },
    {
      name: "GARDENA",
      logo: "logo-gardena"
    },
    {
      name: "WAGO",
      logo: "logo-wago"
    },
    {
      name: "RSM Ebner Stolz",
      logo: "logo-rsm-ebner-stolz"
    }
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.items.forEach(i => {
      var html = `<svg class="w-100"><use xlink:href="assets/svg/client-logos/all.svg#${i.logo}"></use></svg>`;
      var safe = this.sanitizer.bypassSecurityTrustHtml(html);
      i.svg = safe;
    });
  }

}
