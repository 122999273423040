<div class="card card-lg" [class.d-none]="!active" *ngIf="data">

  <div class="card-header border-bottom-0 pb-0">

    <div class="row">

      <div class="col-12 mb-3">
        <div class="d-md-none smaller text-muted">1 | 6</div>
      </div>

      <div class="col-auto">
        <h2 class="card-header-title h1 mb-3">Vielen Dank!</h2>
        <p class="lead">Wir messen Ihrem Feedback eine sehr hohe Bedeutung zu und werden es nutzen, um unsere Leistungen weiter zu verbessern.</p>
      </div>

    </div>

  </div>

  <div class="card-body">

    <div class="row justify-content-center">
      <div class="col-4">

        <img src="assets/svg/sc-illu-check-thumb-up.svg" alt="skillconomy Illu Bot" class="img-fluid">

      </div>

    </div>

  </div>

</div>
