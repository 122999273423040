<section anchor="mission">

  <div class="container">

    <div class="row">

      <div class="col-lg-10 offset-lg-1">

        <div class="row align-items-center">

          <div class="col-4 d-none d-lg-block">
            <img class="img-fluid" src="assets/svg/sc-illu-bot-candidate-present.svg" alt="" />
          </div>
    
          <div class="col-12 col-lg-7 offset-lg-1">
    
            <p class="large">Unsere Ideen sind zugegebenermaßen manchmal größer, als es für ein Unternehmen unserer Größe 
              üblich ist. Wir wollen unsere Vision von Gemeinschaft, Unternehmertum und gesellschaftlicher 
              Entwicklung leben. Wir sind dankbar, wie weit wir auf diesem Weg schon gekommen sind – 
              und stehen immer noch ganz am Anfang.</p>
    
          </div>
    
    
        </div>
        
        <div class="row quote-container">


          <div class="col-12 col-md-8 offset-md-2 mb-6">
         
            <h3 class="heading-serif h2 mb-0 quote">Wir glauben fest daran, dass Erfolg entsteht, wenn Menschen sich in Gemeinschaft entfalten können und wertgeschätzt werden, wie sie sind. </h3>
    
          </div>

        </div>

        <div class="row">
          <div class="col-12 col-lg-7 offset-lg-5">
            <p class="large">Wir sind Profis in dem was wir tun. Aber uns verbindet noch Vieles mit der Zeit, 
              als das Gründer-Team im Wald Buden baute, als es „schlechte Ideen“ noch nicht gab 
              und Erfolg keine KPI war, sondern ein Gefühl.
            </p>
          </div>
        </div>
      </div>
      

    </div>

  </div>

</section>

