import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService, ScApiService } from '@sc-ui';
import { TracingService } from 'src/services/tracing.service';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss']
})
export class BookingConfirmationComponent implements OnInit {

  fullName = '';

  constructor(private route: ActivatedRoute, private ga: GoogleAnalyticsService, private api: ScApiService, private tracingService : TracingService) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe((params: any) => {
      console.log(params);

      this.fullName = params.invitee_full_name;
      var eventType = params.event_type_name;
      var utmSource = params.utm_source;
      var utmContent = params.utm_content;

      if (eventType && eventType == 'Kennenlernen') {
        this.ga.track('call-booking', 'general');
        this.tracingService.traceConversion('booking_complete');
      }

      if (utmSource && utmSource == 'project-briefing' && utmContent) {
        var projectSecret = utmContent;

        var args = {
          EMail: params.invitee_email,
          Start: params.event_start_time
        };

        this.api.post('projects/' + projectSecret + '/briefing', args).subscribe();
      }

      if (utmSource && utmSource == 'project-review' && utmContent) {
        var projectSecret = utmContent;

        var args = {
          EMail: params.invitee_email,
          Start: params.event_start_time
        };

        this.api.post('projects/' + projectSecret + '/review', args).subscribe();

      }
    });
  }

}
