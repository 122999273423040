<div class="row step align-items-center">
    <div class="col-12 col-sm-10 offset-sm-1 order-md-2 col-md-5 offset-md-1 col-lg-4 offset-lg-1">
        <picture>
            <img [src]=" image" [alt]="headline" class="img-fluid w-100">
        </picture>
    </div>
    <div class="text-col col-12 col-sm-10 offset-sm-1 mt-4 order-md-1 col-md-6 offset-md-0 mt-md-0 col-lg-5 offset-lg-1 col-xl-4 offset-xl-2">
        <div class="text-container">            
            <div class="text-number h4">{{ number }}</div>
            <div class="text-content">
                <h4>{{ headline }}</h4>
            <p>
                <ng-content></ng-content>
            </p>
            </div>
            
        </div>

    </div>
</div>