<section class="container pt-8" anchor="benefits">

  <div class="row">
    <div class="col-12 col-md-6 offset-md-2 col-lg-12 offset-lg-0">
      <div class="benefits-grid">

        <div>
          <img src="assets/svg/sc-quality.svg">
          <h4>Einzigartig</h4>
          <p>skillconomy bietet dir Möglichkeiten, die du als Studierender sonst nicht bekommst.</p>
        </div>

        <div>
          <img src="assets/svg/sc-speed.svg">
          <h4>Flexibel</h4>
          <p>Du kannst jederzeit entscheiden, welche Angebote dich interessieren und welche nicht.</p>
        </div>

        <div>
          <img src="assets/svg/sc-efficient.svg">
          <h4>Vertraulich</h4>
          <p>Deine Daten werden niemals ohne dein ausdrückliches Einverständnis an Dritte weitergegeben.</p>
        </div>

      </div>
    </div>
  </div>

</section>
