import { Component } from '@angular/core';
import { JobCheckStepBaseComponent } from 'src/components/job-check-step-base/job-check-step-base.component';

@Component({
  selector: 'app-feedback-step-success',
  templateUrl: './feedback-step-success.component.html',
  styleUrls: ['./feedback-step-success.component.scss']
})
export class FeedbackStepSuccessComponent  extends JobCheckStepBaseComponent {
  onComplete() {
    this.complete.next();
  }
}
