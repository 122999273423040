import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ScApiService } from '@sc-ui';

@Injectable({
  providedIn: 'root'
})
export class TracingService {

  private _isBrowser = true;

  public tracingInfo = {
    contactCode: '',
    utmSource: '',
    firstUtmSource: '',
    referrer: '',
    firstReferrer: '',
    deviceId: ''
  }

  constructor(@Inject(PLATFORM_ID) platformId: string, private route: ActivatedRoute, private router: Router, private api: ScApiService) {
    this._isBrowser = isPlatformBrowser(platformId);
  }

  public init() {

    if (!this._isBrowser) {
      console.warn("TracingService does not work with SSR");
      return;
    }

    this.loadTracingInfo();

    this.tracingInfo.referrer = document.referrer;
    this.tracingInfo.utmSource = '';

    this.route.queryParams.subscribe((params: any) => {

      if (params.id) {
        if (this.tracingInfo.contactCode != params.id) {
          this.tracingInfo.contactCode = params.id;
          this.saveTracingInfo();
        }
      }

      if (params.utm_source) {

        this.tracingInfo.utmSource = params.utm_source;
        this.saveTracingInfo();
      }

      if (params.csc) {
        this.traceCadenceStep(params.csc);
      }

      if (params.id || params.csc || params.utm_source) {
        this.router.navigate(
          [],
          {
            relativeTo: this.route,
            queryParams: { csc: null, id: null, utm_source: null },
            queryParamsHandling: 'merge',
            replaceUrl: true
          });
      }
    });

    
    this.router.events.subscribe((ev: any) => {
      if (ev instanceof NavigationEnd) {
        this.tracePageView();
      }
    });
  }

  trace(traceId, payload)
  {
    if (!this.tracingInfo.deviceId)
    {
      this.initTracingInfo();
      this.traceFirstVisit();
    }

    var trace = {
      TraceId: traceId,
      Payload: JSON.stringify(payload)
    }

    this.api.post('trace', trace).subscribe();
  }


  tracePageView() {

    var currentUrl = this.router.url;

    var payload = {
      Url: currentUrl,
      TracingInfo: this.tracingInfo
    };

    this.trace('page_view', payload);
  }

  traceFirstVisit()
  {
    var currentUrl = encodeURIComponent(this.router.url);

    var payload = {
      Url: currentUrl,
      TracingInfo: this.tracingInfo
    };

    this.trace('first_visit', payload);

  }

  traceConversion(conversionName)
  {
    var currentUrl = encodeURIComponent(this.router.url);

    var payload = {
      Url: currentUrl,
      ConversionName: conversionName,
      TracingInfo: this.tracingInfo
    };

    this.trace('conversion', payload);
  }

  traceCadenceStep(stepCode) {
    if (!this.tracingInfo.contactCode) return;

    var uri = 'csc/' + stepCode;
    this.api.post(uri, 'csc/' + stepCode).subscribe((d: any) => {
      this.tracingInfo.contactCode = d.Id;
      this.saveTracingInfo();
    });
  }

  saveTracingInfo() {
    var json = JSON.stringify(this.tracingInfo);
    localStorage.setItem('tracing', json);
  }

  loadTracingInfo() {

    var json = localStorage.getItem('tracing');

    if (json) {
      var tracingInfo = JSON.parse(json);
      Object.assign(this.tracingInfo, tracingInfo);
    } else {
      console.log("No tracing info present...");      
    }
  }

  initTracingInfo()
  {
    var deviceId = this.generateRandomKey(128);
    this.tracingInfo.deviceId = deviceId;
    this.tracingInfo.firstUtmSource = this.tracingInfo.utmSource;
    this.tracingInfo.firstReferrer = this.tracingInfo.referrer;
    this.saveTracingInfo();
    this.traceFirstVisit();
  }

  generateRandomKey(length) {
    const array = new Uint8Array(length / 8); // length in bytes
    crypto.getRandomValues(array);
    return Array.from(array, byte => ('00' + byte.toString(16)).slice(-2)).join('');
  }
}
