import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-blob2',
  templateUrl: './svg-blob2.component.html',
  styleUrls: ['./svg-blob2.component.scss']
})
export class SvgBlob2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
