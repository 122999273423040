<div class="modal-header">
  <div>
    <h6 class="modal-title">
      <ng-content></ng-content>
    </h6>
  </div>
  <div class="clickable" (click)="closeClick.next()">
    <i class="fa-regular fa-xmark"></i>
  </div>
</div>
