<div class="container p-0">
    <div class="row align-items-end">
      <div class="col">
        <input *ngIf="!message.Payload.IsMultiline" [placeholder]="message.Payload.Placeholder||''" [type]="inputType" class="form-control form-control-text me-sm-3 mb-0" [(ngModel)]="textInput" >
        <textarea #multilineInput *ngIf="message.Payload.IsMultiline" rows="1" [placeholder]="message.Payload.Placeholder||''" class="form-control form-control-text me-sm-3 mb-0" [(ngModel)]="textInput" (ngModelChange)="resizeTextArea()"></textarea>
        <p class="small text-danger" *ngIf="error">{{ error }}</p>
      </div>
      <div class="col-auto pl-0">
        <button class="btn btn-secondary btn-circle send-button" [disabled]="!textInput" (click)="send()">
          <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
        </button>
      </div>
    </div>
</div>