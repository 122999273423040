<section  anchor="garantie">

  <div class="container">

    <h2 class="heading-xl heading-serif">Preise</h2>

    <div class="row align-items-center mt-lg-6">

      <div class="col-12 col-lg-5 offset-lg-2 order-lg-1">

        <h3>KI-Recruiting <br>ab 500 € pro Stelle</h3>

        <p class="larger">
          Woran du merkst, dass wirklich KI drin steckt? <br>Am Preis. 
          Wir heben die Potenziale von KI und geben die Effizienzgewinne an dich weiter. 
          Im persönlichen Gespräch beraten wir dich gerne zu dem für dich passenden Modell.


        </p>

      </div>

      <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-6 offset-md-5 col-lg-4 offset-lg-1 order-lg-0">
        <picture>
          <img src="assets/svg/sc-illu-graph.svg" width="430" height="430" class="img-fluid w-100">
        </picture>
      </div>

    </div>

  </div>

</section>