<div class="container px-0">
  <div class="row">
    <div class="col-12">
      <div class="choices-container scrollbar-minimal">
        <button *ngFor="let choice of message.Payload.Choices" class="btn" [disabled]="!choice.Checked && lockRemainingChoices" [class.btn-secondary]="!choice.Checked" [class.btn-dark]="choice.Checked" (click)="choiceClicked(choice);">
          {{choice.Label}}
        </button>
      </div>
    </div> 
  </div>
  <div class="row align-items-end" *ngIf="message.Payload.MaximumSelectedChoices > 1">
    <div class="col">
        <small *ngIf="getDefactoMaxOptions() > 1 && getSelectedCount() == 0" class="col footnote text-muted">Wähle bis zu {{ getDefactoMaxOptions() }} Optionen</small>
        <small *ngIf="getDefactoMaxOptions() > 1 && getSelectedCount() > 0 && getSelectedCount() < getDefactoMaxOptions()" class="col footnote text-muted">{{ getSelectedCount() }}/{{ getDefactoMaxOptions() }} ausgewählt</small>
        <small *ngIf="getDefactoMaxOptions() > 1 && getSelectedCount() == getDefactoMaxOptions()" class="col footnote text-muted">Maximale Anzahl an Optionen ausgewählt</small>
    </div>
    <div class="col-auto">
      <button class="btn btn-secondary btn-circle send-button" [disabled]="getSelectedCount() < message.Payload.MinimumSelectedChoices" (click)="send()">
        <i class="fa-sharp fa-solid fa-paper-plane-top"></i>
      </button>
    </div>
  </div>
</div>