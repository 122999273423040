<!-- <div class="announcement">
  <div class="container d-flex justify-content-center">
    <app-ticker-span>
      <i class="fa-sharp fa-solid fa-flag me-3"></i>
      <strong class="me-4">Erlebe deine Recruiting-KI live!</strong>
      <span class="small">Zukunft Personal, Kölnmesse, 10.-12.09.2024, Halle 4.1, Stand: C.26 </span>
    </app-ticker-span>

  </div>
</div> -->

<!-- Add show-announcement class when using announcement-->
<sc-headroom upTolerance="5" downTolerance="5" innerClassName="sc-header" [class.scrolled]="scrolled">

  <div class="container-fluid">

    <nav #nav class="sc-nav">

      <a class="sc-logo" routerLink="/home">
        <app-svg-logo></app-svg-logo>
      </a>

      <button id="sc-nav-toggle">

        <svg version="1.1" id="sc-hamburger" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 26 26" enable-background="new 0 0 26 26" xml:space="preserve">
          <g>
            <g id="bar-bottom">
              <path id="dot" fill="#0062D6" d="M3.14,22.05c0.98-0.01,1.76-0.81,1.76-1.79c-0.01-0.98-0.8-1.77-1.78-1.76
                      c-0.98,0-1.77,0.8-1.77,1.78c0,0.47,0.19,0.93,0.53,1.26C2.21,21.87,2.67,22.06,3.14,22.05" />
              <rect id="bar" x="8.6" y="18.5" fill="#0062D6" width="16.64" height="3.55" />
            </g>
            <rect id="bar-mid" x="0.76" y="11.06" fill="#0062D6" width="24.49" height="3.55" />
            <rect id="bar-top" x="0.76" y="3.94" fill="#0062D6" width="24.49" height="3.55" />
          </g>
        </svg>

      </button>

      <div id="sc-main-nav">

        <span class="bubble"></span>

        <app-svg-blob1></app-svg-blob1>

        <ul>
          <li class="me-5">
            <a class="large" (click)="navItemClick()" routerLinkActive="is-active" routerLink="/home">Home</a>
          </li>
          <!-- <li class="me-5">
            <a class="large" (click)="navItemClick()" routerLinkActive="is-active" routerLink="/preise">Preise</a>
          </li> -->
          <li class="me-5">
            <a class="large" (click)="navItemClick()" routerLinkActive="is-active" routerLink="/unternehmen">Unternehmen</a>
          </li>
          <li class="me-0 me-lg-5">
            <a class="large" (click)="navItemClick()" routerLinkActive="is-active" routerLink="/kontakt">Kontakt</a>
          </li>
          <!-- <li class="me-0 me-lg-5">
            <a class="large" (click)="navItemClick()" routerLinkActive="is-active" routerLink="/blog">Blog</a>
          </li> -->
          <li class="d-none d-md-block me-0">
            <a class="large clickable" (click)="navItemClick()" routerLinkActive="is-active" (click)="openPortal()">Login</a>
          </li>

        </ul>

      </div>

      <button class="d-none d-md-block btn btn-primary btn-round btn-round btn-login" scAnchorLink="book" anchorReferencePosition="middle" anchorTargetPosition="middle">Demo buchen</button>

    </nav>
  </div>

</sc-headroom>
