import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-lp-button-link',
  templateUrl: './lp-button-link.component.html',
  styleUrls: ['./lp-button-link.component.scss']
})
export class LpButtonLinkComponent implements OnInit {

  @Input()
  text = "";

  @Output()
  click = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  clicked()
  {
    this.click.emit();
  }

}
