import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ebner-stolz-hero',
  templateUrl: './ebner-stolz-hero.component.html',
  styleUrls: ['./ebner-stolz-hero.component.scss']
})
export class EbnerStolzHeroComponent implements OnInit {

  @Input()
  variant = 'business';

  constructor() { }

  ngOnInit(): void {
  }

}
