<section class="pt-lg-0" #howItWorks>

	<div class="container trigger">
  
	  <!-- <div class="row pin py-4 d-none d-lg-block" [class.pinned]="isPinned">
		<div class="col-12">
		  <nav class="pill-nav">
			<div [class.active]="activeSection == 0" class="btn btn-round me-3 btn-step btn-sm btn-secondary" scAnchorLink="1">Wegweisende KI</div>
			<div [class.active]="activeSection == 1" class="btn btn-round me-3 btn-step btn-sm btn-secondary" scAnchorLink="2">Full Service</div>
			<div [class.active]="activeSection == 2" class="btn btn-round me-3 btn-step btn-sm btn-secondary" scAnchorLink="3">Candidate Experience</div>
		  </nav>
  
		</div>
	  </div> -->
  
	  <div class="row">
  
		<div class="col-12 offset-0 col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-5 offset-lg-0 pr-md-6 col-left">
  
		  <div class="content mb-8 mb-lg-0" scAnchorTarget="1">
  
			<div class="content-marker" data-marker-content="image-1">
			  <h2>Wegweisende KI</h2>
			  <p class="large">
				Die erste KI, die Stellen und Profile besser versteht als ein Mensch. 
				Von Grund auf neu entwickelt von skillconomy.
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
			  </p>
			  <picture class="d-lg-none">
				<img src="assets/img/how-it-works/how-it-works-1-mobile.jpg" class="img-fluid w-100 mt-6">
			  </picture>
			</div>
  
		  </div>
  
		  <div class="content mb-8 mb-lg-0" scAnchorTarget="2">
  
			<div class="content-marker" data-marker-content="image-2">
			  <h2>Full Service</h2>
			  <p class="large">
				Unsere Recruiting-Expertinnen begleiten dich als feste Ansprechperson 
				und sorgen mit ihrem Know-how für eine kontinuierliche Optimierung deiner Projekte.
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
			  </p>
			  <picture class="d-lg-none">
				<img src="assets/img/how-it-works/how-it-works-2-mobile.jpg" class="img-fluid w-100 mt-6">
			  </picture>
			</div>
  
		  </div>
  
		  <div class="content mb-8 mb-lg-0" scAnchorTarget="3">
  
			<div class="content-marker" data-marker-content="image-3">
			  <h2>Candidate Experience</h2>
			  <p class="large">
				Wir gehen über die Individualisierung von Nachrichten hinaus und schaffen für deine Bewerber:innen eine begeisternde Candidate Experience.
				Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
			  </p>
			  <picture class="d-lg-none">
				<img src="assets/img/how-it-works/how-it-works-3-mobile.jpg" class="img-fluid w-100 mt-6">
			  </picture>
			</div>
  
		  </div>
		  
  
		</div>
  
		<aside class="col-6 d-none d-lg-block col-right">
		  <picture id="image-1">
			<img src="assets/img/how-it-works/how-it-works-1-desktop.jpg" width="1051" height="1051"  class="img-fluid w-100">
		  </picture>
		  <picture id="image-2">
			<img src="assets/img/how-it-works/how-it-works-2-desktop.jpg" width="1051" height="1051" class="img-fluid w-100">
		  </picture>
		  <picture id="image-3">
			<img src="assets/img/how-it-works/how-it-works-3-desktop.jpg" width="1051" height="1051" class="img-fluid w-100">
		  </picture>
		</aside>
  
	  </div>
  
	  
	</div>
  
  
  </section>
  
  <div class="container mt-6 mt-lg-0 cta">
	<div class="row justify-content-center">
	  <div class="col-auto">
		
		<button class="btn btn-primary btn-round btn-lg" routerLink="/produkt">Jetzt Demo buchen</button>
		
	  </div>
	</div>
  </div>