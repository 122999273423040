import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-blob6',
  templateUrl: './svg-blob6.component.html',
  styleUrls: ['./svg-blob6.component.scss']
})
export class SvgBlob6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
