import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-logo',
  templateUrl: './svg-logo.component.html',
  styleUrls: ['./svg-logo.component.scss']
})
export class SvgLogoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
