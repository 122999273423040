import { Component, OnInit } from '@angular/core';
 
@Component({
  selector: 'app-home-references',
  templateUrl: './home-references.component.html',
  styleUrls: ['./home-references.component.scss']
})

export class HomeReferencesComponent implements OnInit {

   ngOnInit() {}

}

