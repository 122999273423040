<section>
  <div class="container">

    <div class="row">
      <div class="col-12 col-lg-7 order-2">
        
        <div class="row align-items-end" *ngIf="category">
          <div class="col">
            <div class="h5 mb-1">Kategorie</div>
            <h1 class="mb-0 heading-lg">#{{ category }}</h1>
          </div>

          <div class="col-auto">
            <button class="btn btn-secondary btn-round mt-3" (click)="showAll()">Alle anzeigen</button>
          </div>    
        </div>
      
      </div>
    </div>



    <div class="row post-container" *ngIf="posts">

      <div class="col-12 col-lg-7 order-2">

        <div *ngFor="let post of posts" class="post" routerLink="/blog/{{post.Uri}}">

          <div class="post-header">
            <div class="h5 mb-2">#{{post.Category}}</div>
            <h2 class="mb-5 heading-lg" [innerHTML]="post.Headline"></h2>
          </div>

          <div class="post-image mb-5">
            <picture>
              <img class="img-fluid" src="/assets/blog/{{post.Uri}}/post-preview.jpg" />
            </picture>
          </div>

          <div class="post-teaser mb-5">
            <p class="lead mb-0" [innerHTML]="post.Teaser"></p>
          </div>

          <div class="post-meta">
            <div class="row align-items-center">

              <div class="col-auto">
                <div class="row align-items-center">
                  <div class="image col-auto">
                    <sc-avatar size="xs" [user]="post.Author"></sc-avatar>
                  </div>
                  <div class="name col ms-n3">
                    <div class="name small fw-bold">{{ post.Author.Firstname }} {{ post.Author.Lastname }}</div>
                  </div>
                </div>

              </div>

              <div class="col-auto">

                <div class="timestamp small">{{ post.Timestamp | date : 'dd. MMMM yyyy' : undefined : 'de-DE' }}</div>

              </div>

            </div>

          </div>

        </div>

      </div>

      <div class="col-lg-3 offset-lg-2 order-1 order-lg-2" *ngIf="!category">

        <aside class="categories">

          <h6 class="heading-uppercase">Kategorien</h6>

          <ul class="list-unstyled">
            <li *ngFor="let category of categories" class="mb-2" (click)="selectCategory(category)">
              <a class="h5 clickable">#{{category}}</a>
            </li>
          </ul>

        </aside>

      </div>

    </div>

     <div class="pagination">
      
        <div class="row justify-content-between align-items-center">

          <a class="col-auto link-arrow left text-uppercase small" [class.disabled]="page == 1" (click)="previousPage()"><span class="d-none d-md-inline">Zurück</span></a>
   
            <div class="pagination-items col-auto">
              <a class="pagination-item" *ngIf="page > 1" (click)="previousPage()">{{ page - 1 }}</a>
              <a class="pagination-item active">{{ page}}</a>
              <a class="pagination-item" *ngIf="page + 1 <= totalPages" (click)="nextPage()">{{ page + 1 }}</a>
            </div>
 
        <a class="col-auto link-arrow right text-uppercase small" [class.disabled]="page == totalPages" (click)="nextPage()"><span class="d-none d-md-inline">Weiter</span></a>

        </div>

      </div>

  </div>

</section>

<app-job-check-cta></app-job-check-cta>

<app-contact-form></app-contact-form>
