import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ebner-stolz-testimonial',
  templateUrl: './ebner-stolz-testimonial.component.html',
  styleUrls: ['./ebner-stolz-testimonial.component.scss']
})
export class EbnerStolzTestimonialComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
