<section class="sc-pricing container" anchor="faq">

    <div class="row">

        <div class="col-12">

            <h1 class="title mb-5 mb-lg-6">FAQs</h1>

        </div>

        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 mb-6 pr-lg-6">
            <h5>Wie läuft ein Projekt mit skillconomy ab?</h5>
            <p>
                Als Kund:in erhältst du Zugang zu unserem Web-Portal und kannst dort mit einem Klick ein neues Projekt starten. 
                Im Dialog mit unserer KI wird deine Stelle analysiert und mit dem Bewerbermarkt abgeglichen. Auf der Basis 
                optimiert die KI das Zielprofil und erstellt eine stellenspezifische Jobsite für dich. Nach deiner Freigabe starten 
                wir direkt mit der Ansprache und Vorstellung passender Bewerber:innen. Der KI-Dialog dauert durchschnittlich nur fünf Minuten. 
                Auf Wunsch kann jederzeit auch ein persönliches Briefing mit unseren Recruiter:innen erfolgen.  
            </p>
        </div>

        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 mb-6 pr-lg-6">
            <h5>Für welche Positionen eignet sich der KI-Recruiting-Service?</h5>
            <p>
                Die geringen Kosten und die hohe Effizienz sorgen dafür, dass du skillconomy für all deine
                Fach- und Führungspositionen einsetzen kannst - angefangen bei der Trainee-Stelle bis zu Top-Management-Positionen. 
                In den beruflichen Netzwerken können heute sämtliche Zielgruppen erreicht werden, z.B. aus den Bereichen IT, Engineering, Marketing, 
                Sales, Finance und Management. Häufig falsch eingeschätzt: Auch Positionen ab 150.000 € Jahresgehalt eignen sich sehr gut. 
                Nur für sog. Blue Collar Jobs empfehlen wir dir dagegen andere Kanäle. 
            </p>
        </div>

        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 mb-6 pr-lg-6">
            <h5>Steckt wirklich Künstliche Intelligenz in eurer Lösung?</h5>
            <p>
                Im aktuellen Hype um das Thema KI ist diese Frage mehr als berechtigt. Das Fundament unseres Services ist eine 
                von Grund auf selbst entwickelte KI, die spezifisch für das Recruiting trainiert wurde. 
                Die KI versteht alle Merkmale, die in Kandidatenprofilen sowie Stellenbeschreibungen zu finden sind und deren 
                Beziehungen untereinander. So kann die KI insbesondere Skills, Fachthemen, Jobtitel, Abschlüsse und Karrierepfade
                besser verstehen als ein Mensch. 
            </p>
        </div>

        <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-0 mb-6 pr-lg-6">
            <h5>Was sind die nächsten Schritte?</h5>
            <p>
                Nichts geht über das eigene Erleben - daher zeigen wir dir in einem kurzen Demo-Call anhand einer konkreten Stelle
                von dir, wie die KI arbeitet und wie effizient die Zusammenarbeit abläuft. Solltest du danach die Vorteile von KI 
                für dein Recruiting nutzen wollen, sind wir sofort startklar - Setup-Prozesse oder langwierige Implementierungen gibt es 
                bei uns nicht. Natürlich begleitet dich deine feste Ansprechpartner:in über den gesamten Prozess. 
            </p>
        </div>

    </div>

    <!-- <div class="row align-items-center justify-content-center mt-5">
        <div class="col-auto">
            <app-lp-button (click)="scroll.scrollTo('contact')">Jetzt kennenlernen</app-lp-button>
        </div>
    </div> -->

</section>