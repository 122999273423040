import { Component } from '@angular/core';
import { JobCheckStepBaseComponent } from 'src/components/job-check-step-base/job-check-step-base.component';

@Component({
  selector: 'app-feedback-step-three',
  templateUrl: './feedback-step-three.component.html',
  styleUrls: ['./feedback-step-three.component.scss']
})
export class FeedbackStepThreeComponent extends JobCheckStepBaseComponent {
  options = [
    {
      Label: 'Trifft nicht zu',
      Value: 0
    },
    {
      Label: 'Trifft eher nicht zu',
      Value: 1
    },
    {
      Label: 'Neutral',
      Value: 2
    },
    {
      Label: 'Trifft eher zu',
      Value: 3
    },
    {
      Label: 'Trifft zu',
      Value: 4
    }
  ]

  selectedOption: any;

  onComplete() {
    this.data.Step3 = this.selectedOption.Value;
    this.complete.next();
  }
}
