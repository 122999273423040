<div class="card card-lg" [class.d-none]="!active" *ngIf="data">

	<div class="card-header border-bottom-0 pb-0">
  
	  <div class="row">
  
		<div class="col-12 mb-3">
		  <div class="d-md-none smaller text-muted">1 | 7</div>
		</div>
  
		<div class="col-auto">
		  <h2 class="card-header-title h2">Gibt es etwas, das Sie uns wissen lassen möchten?</h2>
		</div>
  
	  </div>
  
	</div>
  
	<div class="card-body">
		<div class="row mt-5">
			<div class="col-12 mb-md-0">
			  <div class="form-group">
				<label class="large">Ihre Nachricht (optional)</label>
				<autosize-textarea size="lg" [(text)]="data.Comment" (textChange)="change.next()" placeholder="optional"></autosize-textarea>
			  </div>
			  
			  <div class="alert alert-error" *ngIf="hasError">
				<h5 class="alert-heading">Leider kam es zu einem Fehler</h5>
				Bitte klicken Sie <a [href]="errorLink">hier</a>, um Ihre Anfrage per E-Mail zu übermitteln (eine erneute Eingabe der Daten ist nicht erforderlich)
			  </div>
			</div>
		  </div>
	</div>
  
	<div class="card-footer border-top-0 pt-0">
  
	  <div class="row justify-content-between align-items-center">
  
		<div class="col-auto"><a class="text-muted fw-bold clickable" (click)="back.next()"><i class="fa-sharp fa-solid fa-chevron-left fa-xs me-2"></i>zurück</a></div>
		<div class="col-auto d-none d-md-block">Schritt 4 von 4</div>
		<div class="col-auto"><button class="btn btn-primary btn-round btn-lg" (click)="sendFeedback()" [disabled]="isSending">
			<span *ngIf="isSending" class="loader loader-sm loader-white d-inline-block"></span>
			Absenden
		</button></div>
  
	  </div>
  
	</div>
  
  </div>