<section class="sc-cx-teaser container">
  <div class="row align-items-center">
    <div class="col-12 col-md-6 col-lg-7">
      <div class="row">
        <div class="col-12">
          <h1 class="heading-xl heading-serif">Dein persönlicher<br>Karriere-Assistent</h1>
        </div>
        <div class="col-10 offset-2 col-md-12 offset-sm-0 col-lg-9 offset-lg-3">
          <p class="larger">Auf dich zugeschnitten – egal ob im Browser oder über unsere App für iOS und Android. Der intelligente Chatbot spart dir Zeit und Aufwand. Wenn dich ein Angebot interessiert, kannst du sofort in den persönlichen Austausch mit deinem Ansprechpartner gehen.</p>
        </div>
      </div>
    </div>
    <div class="col-10 offset-1 col-md-5 offset-md-1 col-lg-5 offset-lg-0 text-right">
      <div class="mockup">
        <img class="img-fluid" src="assets/img/ebner-stolz/phone-mockup.png" alt="Smartphone Mockup skillconomy Jobsites"></div>
    </div>
  </div>
  <app-svg-blob4></app-svg-blob4>
</section>
