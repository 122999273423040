import { Component, OnInit } from '@angular/core';
import { GoogleAnalyticsService } from '@sc-ui';
import { global } from '../../global';
import { TracingService } from 'src/services/tracing.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  contact = global.contact;

  constructor(private ga : GoogleAnalyticsService, public tracingService : TracingService) { }

  ngOnInit() {
  }

  toPhoneHref(href : string)
  {
    if (!href) return "";
    return "tel:" + href.replace(/:/g, "").replace(/ /g, "",).replace(/-/g, "");
  }

  goToBooking()
  {
    var utmSource = this.tracingService.tracingInfo.utmSource ? this.tracingService.tracingInfo.utmSource : 'website';
    this.ga.track('bookingclick');
    this.tracingService.traceConversion('bookingclick');
    window.open('https://calendly.com/skillconomy-kennenlernen/buchen?utm_source=' + utmSource, '_blank').focus();
  }

}
