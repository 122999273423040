import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-topnavigation',
  templateUrl: './layout-topnavigation.component.html',
  styleUrls: ['./layout-topnavigation.component.scss']
})
export class LayoutTopnavigationComponent implements OnInit {

  isCollapsed: boolean;

  constructor() {
    this.isCollapsed = true;
   }

  ngOnInit() {
  }

}
