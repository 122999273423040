export let posts = [
    {
        "Uri": "2021-12-16-skillconomy-spendet-10000-euro-an-dkhw",
        "Timestamp": "2021/12/16",
        "Headline": "Wie passt skillconomy in Ihre Talent Acquisition Strategie?",
        "Teaser": "Das Recruiting hat sich innerhalb der HR zu einem der spannendsten und strategisch wichtigsten Arbeitsfelder entwickelt - und stark verändert. Da ist einerseits die Machtumkehr zwischen Kandidat:in und Arbeitgeber, die Spielregeln werden neu definiert. Auf der anderen Seite sind Recruiting ...",
        "Category": "neues2",
        "Author": {
          "Firstname": "Lars",
          "Lastname": "Branscheid",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/lars-avatar.jpg"
        }
    },
    {
        "Uri": "2021-09-12-interview-skillconomy-bei-mhp-porsche",
        "Timestamp": "2021/09/12",
        "Headline": "Interview: Recruiting mit skillconomy bei MHP Porsche",
        "Teaser": "Vielen Dank Sarah und Jessica für den Einblick in die tolle Arbeit bei MHP: 'Wir konnten mit skillconomy die Belastung unseres Teams spürbar und nachhaltig senken!'",
        "Category": "wissen",
        "Author": {
          "Firstname": "Jonas",
          "Lastname": "Krömer",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/jonas-avatar.jpg"
        }
    },
    {
        "Uri": "2021-07-18-sommerfest",
        "Timestamp": "2021/07/07",
        "Headline": "Summer of 69 - Wir feiern, dass Sommer ist und...",
        "Teaser": "...dass wir der Recruiting-Dienstleister mit der wahrscheinlich höchsten Kundenzufriedenheit sind!",
        "Category": "leben@skillconomy",
        "Author": {
          "Firstname": "Jonas",
          "Lastname": "Krömer",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/jonas-avatar.jpg"
        }
    },
    {
        "Uri": "2021-07-07-koenigsteiner-ist-neuer-vertriebspartner",
        "Timestamp": "2021/07/07",
        "Headline": "Königsteiner ist neuer Vertriebspartner",
        "Teaser": "Die wohl renommierteste HR-Agentur Deutschlands nimmt skillconomy in ihr Portfolio auf. Wir freuen uns über die schon heute vertrauensvolle Zusammenarbeit!",
        "Category": "neues",
        "Author": {
          "Firstname": "Jonas",
          "Lastname": "Krömer",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/jonas-avatar.jpg"
        }
    }, 
    {
        "Uri": "2021-04-27-alles-frisch",
        "Timestamp": "2021/04/27",
        "Headline": "Alles frisch!",
        "Teaser": "skillconomy 2.0 ist da: Active Sourcing wird mit uns jetzt noch schneller, einfacher und sicherer! Dank Bewerber:innen-Garantie, neuem Portal und verbessertem Prozess.",
        "Category": "neues",
        "Author": {
          "Firstname": "Jonas",
          "Lastname": "Krömer",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/jonas-avatar.jpg"
        }
    },
    {
        "Uri": "2020-11-30-skillconomy-gewinnt-hr-excellence-award",
        "Timestamp": "2020/11/30",
        "Headline": "skillconomy gewinnt HR Excellence Award!",
        "Teaser": "Für unser gemeinsames Pilotprojekt #gutesCARMA haben wir es mit RSM Ebner Stolz auf den 2. Platz in der Kategorie 'Candidate Experience (Großunternehmen)' geschafft.",
        "Category": "neues",
        "Author": {
          "Firstname": "Jonas",
          "Lastname": "Krömer",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/jonas-avatar.jpg"
        }
    },
    {
        "Uri": "2021-06-06-so-passt-skillconomy-in-ihre-talent-acquisition-strategie",
        "Timestamp": "2020/06/06",
        "Headline": "Wie passt skillconomy in Ihre Talent Acquisition Strategie?",
        "Teaser": "Ein geschicktes Zusammenspiel zwischen skillconomy und Ihrer inhouse Talent Acquisition ist der Schlüssel zum Erfolg - auch wenn Sie Active Sourcing schon erfolgreich nutzen!",
        "Category": "wissen",
        "Author": {
          "Firstname": "Jonas",
          "Lastname": "Krömer",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/jonas-avatar.jpg"
        }
    },
    {
        "Uri": "2019-11-05-skillconomy-serie-im-saatkorn-blog",
        "Timestamp": "2019/11/05",
        "Headline": "skillconomy-Serie in Gero Hesse's Saatkorn-Blog: Alles über Active Sourcing!",
        "Teaser": "Der erste Artikel unserer sechsteiligen Serie über Active Sourcing ist im Blog von Gero Hesse gestartet. Wir freuen uns riesig, an so prominenter Stelle ausführlich und differenziert über unser Lieblingsthema sprechen zu können!",
        "Category": "wissen",
        "Author": {
          "Firstname": "Jonas",
          "Lastname": "Krömer",
          "Image": "https://screcruiting.blob.core.windows.net/publicassets/jonas-avatar.jpg"
        }
    }
]