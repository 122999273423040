import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFixPosition]'
})
export class FixPositionDirective {

  constructor(private elementRef: ElementRef) {

    setTimeout(() => {
      var el = this.elementRef.nativeElement;
      var width = el.clientWidth;
      el.style.width = width + 'px';
      el.style.position = 'fixed';
    }, 10);

  }

}
