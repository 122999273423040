<div class="post" *ngFor="let post of posts" routerLink="/blog/{{post.Uri}}">

  <div class="row">

    <div class="col-lg-6">

      <div class="post-image mb-5 mb-lg-0">
        <picture>
          <img class="img-fluid" src="/assets/blog/{{post.Uri}}/post-preview.jpg" />
        </picture>
      </div>

    </div>

    <div class="col-lg-5 offset-lg-1">
      <div class="post-header">
        <div class="h5 mb-3">{{post?.Category}}</div>
        <h3 class="mb-4 h2" [innerHTML]="post.Headline"></h3>
      </div>

      <div class="post-teaser mb-4">
        <p class="lead mb-0" [innerHTML]="post.Teaser"></p>
      </div>

      <div class="post-meta">
        <div class="row align-items-center">

          <div class="col-auto">
            <div class="row align-items-center">
              <div class="image col-auto">
                <sc-avatar size="xs" [user]="post.Author"></sc-avatar>
              </div>
              <div class="name col ms-n3">
                <div class="name small fw-bold">{{ post.Author.Firstname }} {{ post.Author.Lastname }}</div>
              </div>
            </div>

          </div>

          <div class="col-auto">

            <div class="timestamp small">{{ post.Timestamp | date : 'dd. MMMM yyyy' : undefined : 'de-DE' }}</div>

          </div>

        </div>

      </div>
    </div>

  </div>

</div>
