import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sc-modal-header',
  templateUrl: './modal-header.component.html',
  styleUrls: ['./modal-header.component.scss']
})
export class ModalHeaderComponent implements OnInit {

  @Output()
  closeClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

}
