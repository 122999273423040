import { Injectable, PLATFORM_ID, Inject, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { AuthInfo } from "./AuthInfo";
import { filter, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { ApiConfig } from './api-config';
import { isPlatformBrowser } from '@angular/common';

@Injectable({ providedIn: "root" })
export class ScApiService {

  defaultHost: string = "api/";

  userId: string;

  userEMail: string;

  userToken: string;

  isLoggedIn: boolean = false;

  loggedIn = new EventEmitter<any>();

  loggedOut = new EventEmitter<any>();

  tokenChanged = new EventEmitter<any>();

  private _isBrowser: boolean;

  constructor(config: ApiConfig, 
    private http: HttpClient, 
    private router: Router, 
    @Inject(PLATFORM_ID) platformId: string,) 
  {
    this._isBrowser = isPlatformBrowser(platformId);
    this.loadAuthInfo();
    this.defaultHost = config.host;
  }

  private getUri(res: string, host: string = undefined) {
    if (!host) host = this.defaultHost;
    if (!host.endsWith('/')) host += '/';
    if (res.startsWith('https://')) return res;
    return host + res;
  }

  appendAuthHeader(headers: HttpHeaders): HttpHeaders {

    var h = headers
      .append('Accept', 'application/json')
      .append('Cache-Control', 'no-cache')
      .append('Pragma', 'no-cache')
      .append('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
      .append('If-Modified-Since', '0');

    if (this.userEMail && this.userToken) {
      h = h.append('ScAuthorization', this.userEMail + ' ' + this.userToken);
    }

    return h;
  }

  getHeaders(): HttpHeaders {
    var headers = this.appendAuthHeader(new HttpHeaders());
    return headers;
  }

  getRequestOptions() {
    var options = {
      headers: this.getHeaders()
    };

    return options;
  }

  login(eMail: string, password: string) {

    var uri = this.getUri('login');
    var data = { EMail: eMail, Password: password };
    var response = this.http.post(uri, data);

    return response.pipe(
      tap((response: any) => {
        this.handleLoginResult(response);
      })
    );
  }

  loginWithToken(eMail: string, token: string) {
    var uri = this.getUri('tokenlogin');
    var data = { EMail: eMail, Token: token };
    var response = this.http.post(uri, data);

    return response.pipe(
      tap((response: any) => {
        this.handleLoginResult(response);
      })
    );
  }

  register(args: any) {

    var uri = this.getUri('register');
    var response = this.http.post(uri, args);

    return response.pipe(
      tap((response: any) => {
        this.handleLoginResult(response);
      })
    );
  }

  changePassword(oldPassword: string, newPassword: string) {

    var uri = this.getUri('account/changepassword');
    var data = { NewPassword: newPassword, Password: oldPassword };
    var response = this.http.post(uri, data, this.getRequestOptions());

    return response.pipe(
      tap((response: any) => {
        this.userToken = response.Token;
        this.storePassword(this.userEMail, newPassword);
        this.saveAuthInfo();
        this.tokenChanged.next(this.userToken);
      })
    );
  }

  private async storePassword(username: string, password: string): Promise<Credential | null> {
    // @ts-ignore
    if (!window.PasswordCredential) {
      return Promise.resolve(null);
    }
    // @ts-ignore

    const cred = new window.PasswordCredential({
      id: username,
      password,
      name: username
    });

    return navigator.credentials.store(cred);
  }

  logout() {

    if (!this._isBrowser) return;

    var logout$ = new Observable((observer) => {
      observer.next();
      observer.complete();
    });

    return logout$.pipe(tap(() => {

      this.clearAuthInfo();
      this.loggedOut.emit(null);
    }));
  }

  redirectAnonymousToLogin(e: any) {
    if (e?.error?.isLoggedIn != false) return;
    var currentRoute = this.router.url;
    var encodedUri = encodeURI(currentRoute);
    this.router.navigate(['/login'], { queryParams: { target: encodedUri } });
  }

  get<T>(res, host = undefined) {
    var hasQueryParams = res.indexOf('?') > 0;
    var ts = new Date().getTime();
    var append = hasQueryParams ? '&ts=' + ts : '?ts=' + ts;

    var uri = this.getUri(res + append, host);
    return this.http.get<T>(uri, this.getRequestOptions());
  }

  post(res, data, host = undefined) {
    var uri = this.getUri(res, host);
    return this.http.post(uri, data, this.getRequestOptions());
  }

  put(res, data, host = undefined) {
    var uri = this.getUri(res, host);
    return this.http.put(uri, data, this.getRequestOptions());
  }

  delete(res, host = undefined) {
    var uri = this.getUri(res, host);
    return this.http.delete(uri, this.getRequestOptions());
  }

  request<T>(request: HttpRequest<T>, res = undefined, host = undefined) {
    var currentHeaders = request.headers;
    if (!res) res = request.url;
    var uri = this.getUri(res, host);
    var modifiedHeaders = currentHeaders ? this.appendAuthHeader(currentHeaders) : this.getHeaders();
    var modifiedRequest = request.clone({ url: uri, headers: modifiedHeaders });
    return this.http.request(modifiedRequest);
  }

  private handleLoginResult(response) {

    if (!response.Succeeded) return;

    this.userId = response.Id;
    this.userEMail = response.EMail;
    this.userToken = response.Token;

    this.saveAuthInfo();
    this.isLoggedIn = true;
    this.loggedIn.emit();
  }

  private saveAuthInfo() {
    if (!this._isBrowser) return;
    var authInfo = new AuthInfo();
    authInfo.eMail = this.userEMail;
    authInfo.token = this.userToken;
    authInfo.id = this.userId;
    var json = JSON.stringify(authInfo);
    localStorage.setItem("auth", json);
  }

  private loadAuthInfo() {
    if (!this._isBrowser) return;

    var json = localStorage.getItem("auth");
    if (!json) return;

    var auth = new AuthInfo;

    Object.assign(auth, JSON.parse(json));

    this.userEMail = auth.eMail;
    this.userToken = auth.token;
    this.isLoggedIn = true;
    this.userId = auth.id;
  }

  private clearAuthInfo() {
    localStorage.removeItem("auth");
    this.userEMail = "";
    this.userToken = "";
    this.userId = "";
    this.isLoggedIn = false;
  }
}
