import { ApiConfig, ScUiModule } from '@sc-ui';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';


import { BlogPostComponent } from './views/blog-post/blog-post.component';
import { CompanyComponent } from './views/company/company.component';
import { ContactComponent } from './views/contact/contact.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { EbnerCandidateTrackComponent } from './views/ebner-stolz/ebner-stolz.component';
import { EbnerStolzAppComponent } from './views/ebner-stolz/ebner-stolz-app/ebner-stolz-app.component';
import { EbnerStolzBenefitsComponent } from './views/ebner-stolz/ebner-stolz-benefits/ebner-stolz-benefits.component';
import { EbnerStolzCtaComponent } from './views/ebner-stolz/ebner-stolz-cta/ebner-stolz-cta.component';
import { EbnerStolzHeroComponent } from './views/ebner-stolz/ebner-stolz-hero/ebner-stolz-hero.component';
import { EbnerStolzHowItWorksComponent } from './views/ebner-stolz/ebner-stolz-how-it-works/ebner-stolz-how-it-works.component';
import { EbnerStolzHowItWorksStepComponent } from './views/ebner-stolz/ebner-stolz-how-it-works/ebner-stolz-how-it-works-step/ebner-stolz-how-it-works-step.component';
import { EbnerStolzReferencesComponent } from './views/ebner-stolz/ebner-stolz-references/ebner-stolz-references.component';
import { EbnerStolzTestimonialComponent } from './views/ebner-stolz/ebner-stolz-testimonial/ebner-stolz-testimonial.component';
import { environment } from './environment.prod';
import { FooterComponent } from './components/footer/footer.component';
import { GaTrackClickDirective } from './directives/ga-track-click.directive';
import { HeaderComponent } from './components/header/header.component';
import { HeroListcyclerDirective } from './directives/hero-listcycler.directive';
import { HomeBenefitsComponent } from './views/home/home-benefits/home-benefits.component';
import { HomeCompanyComponent } from './views/home/home-company/home-company.component';
import { HomeComponent } from './views/home/home.component';
import { HomeFaqComponent } from './views/home/home-faq/home-faq.component';
import { HomeHeroComponent } from './views/home/home-hero/home-hero.component';
import { HomeHowItWorksComponent } from './views/home/home-how-it-works/home-how-it-works.component';
import { HomeReferencesComponent } from './views/home/home-references/home-references.component';
import { HttpClientModule } from '@angular/common/http';
import { ImprintComponent } from './views/imprint/imprint.component';
import { LpButtonComponent } from './components/lp-button/lp-button.component';
import { LpButtonLinkComponent } from './components/lp-button-link/lp-button-link.component';
import { NotFoundComponent } from './views/not-found/not-found.component';
import { PrivacyNoticeComponent } from './views/privacy-notice/privacy-notice.component';
import { ScChatbotModule } from '@sc-ui-chatbot';
import { LayoutTopnavigationComponent } from './layouts/layout-topnavigation/layout-topnavigation.component';
import { SvgBlob1Component } from './components/svg-blob1/svg-blob1.component';
import { SvgBlob2Component } from './components/svg-blob2/svg-blob2.component';
import { SvgBlob3Component } from './components/svg-blob3/svg-blob3.component';
import { SvgBlob4Component } from './components/svg-blob4/svg-blob4.component';
import { SvgBlob5Component } from './components/svg-blob5/svg-blob5.component';
import { SvgLogoComponent } from './components/svg-logo/svg-logo.component';
import { SvgPaperPlaneComponent } from './components/svg-paper-plane/svg-paper-plane.component';
import { UnsubscribeComponent } from './views/unsubscribe/unsubscribe.component';
import { FileDownloadComponent } from './components/file-download/file-download.component';
import { QuestionAnswerComponent } from './components/question-answer/question-answer.component';
import { VideoComponent } from './components/video/video.component';
import { TopicsComponent } from './components/topics/topics.component';
import { CompanyHeroComponent } from './views/company/company-hero/company-hero.component';
import { CompanyMissionStatementComponent } from './views/company/company-mission-statement/company-mission-statement.component';
import { CompanyValuesComponent } from './views/company/company-values/company-values.component';
import { SvgBlob6Component } from './components/svg-blob6/svg-blob6.component';
import { HeaderNoNavComponent } from './components/header-no-nav/header-no-nav.component';
import { LayoutHeaderNoNavComponent } from './layouts/layout-header-no-nav/layout-header-no-nav.component';
import { BlogComponent } from './views/blog/blog.component';
import { HomeBlogComponent } from './views/home/home-blog/home-blog.component';
import { BlogPreviewComponent } from './components/blog-preview/blog-preview.component';
import { JobCheckCtaComponent } from './components/job-check-cta/job-check-cta.component';
import { JobCheckCtaCardComponent } from './components/job-check-cta-card/job-check-cta-card.component';
import { FeedbackComponent } from './views/feedback/feedback.component';
import { FeedbackStepOneComponent } from './views/feedback/feedback-step-1/feedback-step-one.component';
import { FeedbackStepTwoComponent } from './views/feedback/feedback-step-2/feedback-step-two.component';
import { FeedbackStepThreeComponent } from './views/feedback/feedback-step-3/feedback-step-three.component';
import { FeedbackStepFourComponent } from './views/feedback/feedback-step-4/feedback-step-four.component';
import { FeedbackStepFiveComponent } from './views/feedback/feedback-step-5/feedback-step-five.component';
import { FeedbackStepSuccessComponent } from './views/feedback/feedback-step-success/feedback-step-success.component';
import { HomeUspsComponent } from './views/home/home-usps/home-usps.component';
import { TickerSpanComponent } from './components/ticker-span/ticker-span.component';
import { PricingGuaranteeComponent } from './views/pricing/pricing-guarantee/pricing-guarantee.component';
import { ProductFactsComponent } from './views/home/product-facts/product-facts.component';
import { JobCheckHeaderComponent } from './components/job-check-header/job-check-header.component';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

@NgModule({
  declarations: [
    AppComponent,
    BlogPostComponent,
    CompanyComponent,
    ContactComponent,
    ContactFormComponent,
    EbnerCandidateTrackComponent,
    EbnerStolzAppComponent,
    EbnerStolzBenefitsComponent,
    EbnerStolzCtaComponent,
    EbnerStolzHeroComponent,
    EbnerStolzHowItWorksComponent,
    EbnerStolzHowItWorksStepComponent,
    EbnerStolzReferencesComponent,
    EbnerStolzTestimonialComponent,
    FooterComponent,
    GaTrackClickDirective,
    HeaderComponent,
    HeroListcyclerDirective,
    HomeBenefitsComponent,
    HomeCompanyComponent,
    HomeComponent,
    HomeFaqComponent,
    HomeHeroComponent,
    HomeHowItWorksComponent,
    HomeReferencesComponent,
    ImprintComponent,
    LpButtonComponent,
    LpButtonLinkComponent,
    NotFoundComponent,
    PrivacyNoticeComponent,
    LayoutTopnavigationComponent,
    SvgBlob1Component,
    SvgBlob2Component,
    SvgBlob3Component,
    SvgBlob4Component,
    SvgBlob5Component,
    SvgLogoComponent,
    SvgPaperPlaneComponent,
    UnsubscribeComponent,
    FileDownloadComponent,
    QuestionAnswerComponent,
    VideoComponent,
    TopicsComponent,
    CompanyHeroComponent,
    CompanyMissionStatementComponent,
    CompanyValuesComponent,
    SvgBlob6Component,
    HeaderNoNavComponent,
    LayoutHeaderNoNavComponent,
    BlogComponent,
    HomeBlogComponent,
    BlogPreviewComponent,
    JobCheckCtaComponent,
    JobCheckCtaCardComponent,
    FeedbackComponent,
    FeedbackStepOneComponent,
    FeedbackStepTwoComponent,
    FeedbackStepThreeComponent,
    FeedbackStepFourComponent,
    FeedbackStepFiveComponent,
    FeedbackStepSuccessComponent,
    HomeUspsComponent,
    TickerSpanComponent,
    PricingGuaranteeComponent,
    ProductFactsComponent,
    JobCheckHeaderComponent
  ],
  imports: [   
    BrowserTransferStateModule,
    AppRoutingModule,
    FormsModule,
    ScUiModule,
    ScChatbotModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule
  ],
  entryComponents: [
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "de-DE" },
    { provide: ApiConfig, 
      
      useValue: 
      {
        host: environment.api
      }
    } 
   ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule { }
