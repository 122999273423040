<section class="sc-hero">

  <div class="particles">
    <div class="particle rellax" data-rellax-speed="1.8"></div>
    <div class="particle rellax" data-rellax-speed="0.8"></div>
    <div class="particle rellax" data-rellax-speed="0.5"></div>
    <div class="particle rellax" data-rellax-speed="0.4"></div>
    <div class="particle rellax" data-rellax-speed="1.6"></div>
  </div>

  <div class="container">

      <div class="row align-items-center">
      
          <div class="col-12 col-lg-6">

            <div class="row">
      
              <div class="col-12 col-lg-12">
                <h1 class="heading-xl heading-serif">Innovationen <br class="d-none d-lg-inline">im Recruiting</h1>
              </div>             
              
                <div class="col-12 col-md-10 col-lg-12 offset-lg-0">
                    <p class="larger">
                      Alles, was wir tun, zielt darauf ab, gute Beziehungen zu schaffen. Egal ob in unserem Team oder für unsere Kandidat:innen und
                      Kund:innen. Dabei entwicklen wir eigene Technologien und Systeme, die Innovationen für das Recruiting schaffen: 
                      Vom ersten Jobsite-Chatbot im Jahr 2014 bis zur eigenen Recruiting-KI zehn Jahre später. 
                    </p>
                </div>
                
            </div>
      
          </div>
          
          <div class="col-12 col-sm-10 col-md-7 offset-md-2 col-lg-5 offset-lg-1 mt-6 mt-lg-0">
            <img src="assets/img/sc-unternehmen-header.jpg" width="500" height="683" class="img-fluid">
          </div>

      </div>
      <app-svg-blob3></app-svg-blob3>

  </div>

</section>












