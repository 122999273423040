import { Component, OnInit } from '@angular/core';

declare var Rellax : any;


@Component({
  selector: 'app-company-hero',
  templateUrl: './company-hero.component.html',
  styleUrls: ['./company-hero.component.scss']
})
export class CompanyHeroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(typeof Rellax === "undefined") 
    {
      console.log('Rellax not defined');
    }
    else
    {
      var rellax = new Rellax('.rellax');
    }    
  }

}
