import { Component, ViewChild, Output, Input, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ChatsService } from '@sc-ui-chatbot';
import { interval, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { setTokenSourceMapRange } from 'typescript';
import { MessageScrollContainerComponent } from '../message-scroll-container/message-scroll-container.component';

@Component({
  selector: 'sc-chatbot-stream',
  templateUrl: './stream.component.html',
  styleUrls: ['./stream.component.scss']
})
export class ScChatbotStreamComponent implements OnChanges, OnDestroy {

  private _isLoadedToBeginning = false;

  private _chatStopped$ = new Subject<any>();

  @ViewChild(MessageScrollContainerComponent)
  public scrollContainer: MessageScrollContainerComponent;

  isLoading = false;

  showLoader = false;

  messages: any = [];

  newestMessage : any;

  showNewMessageButton = false;

  isOffline = false;

  isAcknowledgmentActive = true;

  @Input()
  isReadOnly = false;

  @Input()
  chat: any;

  @Output()
  onEndOfChat = new EventEmitter<any>();

  @Output()
  onChatError = new EventEmitter<any>();

  constructor(private changeRef: ChangeDetectorRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.chat) return;
    this.initialize();
  }

  ngOnDestroy() {
    this._chatStopped$.next();
  }

  initialize() {
    this._chatStopped$.next();
    this._isLoadedToBeginning = false;
    this.messages = [];

    this.chat.onMessageReceived.pipe(takeUntil(this._chatStopped$))
      .pipe(filter((args: any) => {
        return this.messages.length && this.messages[this.messages.length - 1].Index < args.CurrentMessageIndex;
      }))
      .subscribe(() => {
        this.checkForUpdates();
      });

    interval(15000).pipe(takeUntil(this._chatStopped$)).subscribe(() => {
      this.checkForUpdates();
    });

    this.loadOlderMessages();
  }

  loadOlderMessages() {

    if (this._isLoadedToBeginning) return;
    if (this.isLoading) return;

    this.isLoading = true;
    this.showLoaderSoon();

    var lowestMessageIndex = this.messages.length > 0 ?
      this.messages[0].Index :
      this.chat.CurrentMessageIndex + 1;

    var startIndex = lowestMessageIndex - 20;
    var endIndex = lowestMessageIndex - 1;

    this.chat.getMessages(startIndex, endIndex).subscribe((messages: any) => {

      this.scrollContainer.persistScrollPosition();

      messages.forEach(m => {
        m.History = true;
        m.isSent = true;
        this.messages.splice(0, 0, m);
      });

      
      this.newestMessage = this.messages[this.messages.length - 1];
      this._isLoadedToBeginning = this.messages.length == 0 || this.messages[0].Index == 1;
      this.isLoading = false;

      this.changeRef.detectChanges();
      this.scrollContainer.restoreScrollPosition();

      if (!this._isLoadedToBeginning && !this.scrollContainer.isScrollable()) {
        console.log('keep loading')
        this.loadOlderMessages();
      }
      else {
        this.hideLoader();
      }
    });
  }

  checkForUpdates() {

    if (this['_isLoadingNewerMessages']) return;
    this['_isLoadingNewerMessages'] = true;

    var maintainScrollPosition = !this.scrollContainer.isScrolledToNewest();
    if (maintainScrollPosition) this.scrollContainer.persistScrollPosition();

    var sentMessages = this.messages.filter(m => m.Index);
    var nextMessageIndex = sentMessages.length > 0 ?
      sentMessages[sentMessages.length - 1].Index + 1 :
      this.chat.CurrentMessageIndex + 1;

    this.chat.getMessages(nextMessageIndex, nextMessageIndex + 100).subscribe(
      (messages: any) => {

        if (messages?.length > 0) {

          var isContainerScrolled = !this.scrollContainer.isScrolledToNewest();
          if (isContainerScrolled) this.scrollContainer.persistScrollPosition();
          this.scrollContainer.persistScrollPosition();

          messages.reverse().forEach(m => {
            m.isSent = true;
            this.messages.push(m);
          });

          this.newestMessage = this.messages[this.messages.length - 1];
          this.changeRef.detectChanges();

          // if (isContainerScrolled) {

          //   this.changeRef.detectChanges();
          //   this.scrollContainer.restoreScrollPosition();

          //   if (messages.some(m => m.SenderId != this.chat.UserId)) {
          //     this.showNewMessageButtonSoon();
          //   }
          // }
          // else {
          //   this.scrollContainer.scrollToNewest();
          //   this.acknowledge();
          // }

          this.scrollContainer.lockToEnd(1000);
          // setTimeout(() => this.scrollContainer.scrollToNewest(), 3000);
        }

        this['_isLoadingNewerMessages'] = false;
      },
      (error: any) => {
        this['_isLoadingNewerMessages'] = false;
      });
  }

  sendMessage(message: any) {

    if (this.isReadOnly) return;

    message.ChatId = this.chat.Id;
    message.StepId = this.messages[this.messages.length - 1]?.StepId;
    message.Sender = this.chat.User;
    message.SenderId = this.chat.UserId;
    message.isSent = false;
    message.isSending = true;
    message.hasSendingFailed = false;

    var index = this.messages.indexOf(message);

    if (index > 0) {
      this.messages.splice(index, 1);
      this.messages.push(message);
      this.changeRef.detectChanges();
    }
    if (index < 0) {
      this.messages.push(message);
      this.changeRef.detectChanges();
    }
    
    this.scrollContainer.lockToEnd(1000);

    this.chat.sendMessage(message).subscribe(
      (d: any) => {
        message.isSent = true;
        message.isSending = false;
        message.Index = d.Index;
        if (message.Index < d.CurrentMessageIndex) this.checkForUpdates();
      },
      (error: any) => {
        message.isSent = false;
        message.isSending = false;
        message.hasSendingFailed = true;
      }
    );
  }

  activateAcknowledgments() {
    console.log('activating ack')
    this.isAcknowledgmentActive = true;
    if (this.scrollContainer.isScrolledToNewest()) this.acknowledge();
  }

  deactivateAcknowledgments() {
    console.log('deactivating ack')
    this.isAcknowledgmentActive = false;
  }

  onScrolledToNewest() {
    console.log('scrolled to newest');
    this.hideNewMessageButton();
    if (this.scrollContainer.isScrolledToNewest()) this.acknowledge();
  }

  private acknowledge() {
    if (!this.isAcknowledgmentActive) return;
    this.chat.acknowledge();
  }

  private showLoaderSoon() {
    clearTimeout(this["_hideHistoryLoaderTimeout"]);
    this["_showHistoryLoaderTimeout"] = setTimeout(() => {
      this.showLoader = true;
    }, 100);
  }

  private hideLoader() {
    clearTimeout(this["_showHistoryLoaderTimeout"]);
    this["_hideHistoryLoaderTimeout"] = setTimeout(() => {
      this.showLoader = false;
    }, 850);
  }

  private showNewMessageButtonSoon() {
    if (!this.scrollContainer.isScrollable()) return;
    console.log('showing msg button soon');
    this["_newMessageButtonTimeout"] = setTimeout(() => {
      this.showNewMessageButton = true;
    }, 500);
  }

  private hideNewMessageButton() {
    clearTimeout(this["_newMessageButtonTimeout"]);
    this.showNewMessageButton = false;
  }
}

