<section class="container">

	<div class="row">
		<div class="col-12">
			<h2 class="heading-xl heading-serif">Besseres Recruiting mit KI</h2>
			<p class="larger">
				Mit unserem Service nutzt du die Vorteile von KI für dein Recruiting <br>- ohne deine Prozesse ändern zu müssen.
			</p>
		</div>
	</div>

	<div class="row mt-6">
		<div class="col-10 offset-1 col-md-5 offset-md-0 mb-6">
			<img src="assets/img/briefingchat.png" width="1444" height="1600" class="w-100 img-fluid" />
		</div>
		<div class="col-12 col-md-6 offset-md-1 col-lg-5 col-xl-4">
			<div class="mb-6">
				<h3>Full-Service</h3>
				<p class="large">
					Unsere Recruiting-Expert:innen begleiten dich als feste Ansprechperson und sorgen mit ihrem Know-how für eine kontinuierliche Optimierung deiner Projekte.
				</p>
			</div>
			<div class="mb-6">
				<h3>Wegweisende KI</h3>
				<p class="large">
					Die erste KI, die Stellen und Profile besser versteht als ein Mensch. Von Grund auf neu entwickelt von skillconomy. 
				</p>
			</div>
			<div>
				<h3>Candidate Experience</h3>
				<p class="large">
					Wir gehen über die Individualisierung von Nachrichten hinaus und schaffen für deine Bewerber:innen eine begeisternde Candidate Experience.
				</p>
			</div>
		</div>
	</div>

</section>