<section>

  <div class="container">

    <!-- <div class="row">

      <div class="col-12">

        <div class="video-container ratio ratio-16x9">
          <video poster="assets/video/moodshots_werte.jpg" loop muted playsinline autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
            <source src="assets/video/moodshots_werte.mp4" type="video/mp4">
            Ihr Browser unterstützt den HTML5 Video Tag nicht.
          </video>
        </div>

      </div>

    </div> -->

    <div class="row mt-8 mb-8" anchor="werte">

      <div class="col-12 col-md-8 col-lg-6 offset-md-2 offset-lg-3 text-center">

        <h2>Unsere Werte</h2>

        <p class="larger">
          Wir sind Profis in dem was wir tun. Aber uns verbindet noch Vieles mit der Zeit, 
              als das Gründer-Team im Wald Buden baute, als es „schlechte Ideen“ noch nicht gab 
              und Erfolg keine KPI, sondern ein Gefühl war.       
        </p>

      </div>

    </div>

    <div class="row">

      <div class="col-lg-10 offset-lg-1">
        <div class="value-grid">

          <div class="value-grid-item">

            <picture>
              <img class="img-fluid" src="assets/img/unternehmen-werte-1.png" width="692" height="716">
            </picture>

            <h3>Human First</h3>
            <p class="large">
              Wir sind nicht die ersten, die von sich behaupten, den Menschen
              in den Mittelpunkt zu stellen. Wir meinen es aber überdurchschnittlich ernst:
              Ob Mitarbeiter:innen, unsere Kund:innen oder die Kandidat:innen: alles was wir
              tun beginnt mit ernstgemeinter Wertschätzung und zielt darauf ab, gute Beziehungen zu schaffen. 
            </p>

          </div>

          <div class="value-grid-item">

            <picture>
              <img class="img-fluid" src="assets/img/sc-data-analytics2.jpg" width="692" height="716">
            </picture>

            <h3>Leading Edge</h3>
            <p class="large">
              Neueste Technologien nutzen, um über den Status Quo hinauszugehen - unser gemeinsamer Antrieb ist es, 
              Innovationen zu entwicklen, die das Recruiting nachhaltig verbessern. Dazu verknüpfen wir unsere 
              langjährige Expertise in Softwareentwicklung und Künstlicher Intelligenz mit der Erfahrung aus 
              tausenden Recruiting-Projekten.
            </p>

          </div>

          <div class="value-grid-item">

            <picture>
              <img class="img-fluid" src="assets/img/home-unternehmen-2024.png" width="692" height="716">
            </picture>

            <h3>Ende zu Ende</h3>
            <p class="large">
              Wir schaffen Mehrwerte für unsere Kandidat:innen und Kund:innen, die in ihrem (Arbeits-)Leben einen echten Unterschied machen. 
              Dabei machen wir die Dinge so einfach wie möglich, reduzieren Komplexität auf das Notwendige und 
              bieten eine User Experience, die begeistert.</p>

          </div>

          <div class="value-grid-item">

            <picture>
              <img class="img-fluid" src="assets/img/unternehmen-werte-3.png" width="692" height="716">
            </picture>

            <h3>Teil des Ganzen</h3>
            <p class="large">
              Wir leben unsere Vorstellung von Gemeinschaft und Unternehmertum. Das bedeutet für uns, dass wir Verantwortung
              übernehmen - in der täglichen Arbeit mit unseren Kund:innen und darüber hinaus für die Gesellschaft und Umwelt, 
              in der wir leben und zu der wir etwas beitragen wollen. </p>

          </div>

        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-12 col-md-8 offset-md-2">

        <h3 class="heading-serif h1 mb-0 mt-8 quote">
          Wir glauben fest daran, dass Erfolg entsteht, wenn Menschen sich in Gemeinschaft entfalten können und wertgeschätzt werden, 
          wie sie sind.
        </h3>

      </div>

    </div>

  </div>

</section>
