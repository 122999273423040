<section class="sc-selling-points container">

  <div class="row">

    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2 class="heading-xl heading-serif">Deine Vorteile</h2>
        </div>
      </div>
    </div>

    <div class="container pt-5" anchor="benefits">

      <div class="row">
        <div class="col-12 col-md-10 offset-md-1 col-lg-12 offset-lg-0">

          <div class="benefits-grid">

            <div>
              <img width="150" height="150" src="assets/img/icon-clock.png">
              <h4>
                10x schneller
              </h4>
              <p class="large">
                Durchschnittlich 90% Zeitersparnis und verkürzte time-to-hire durch KI-Sourcing.
                So kannst du deine Stellen schneller besetzen.
              </p>
            </div>

            <div>
              <img width="150" height="150" src="assets/img/icon-cup.png">
              <h4>2x effektiver</h4>
              <p class="large">
                100% höhere Rücklaufquoten dank individualisierter Candidate Experience.
                Begeisterte Bewerber:innen inklusive.
              </p>
            </div>

            <div>
              <img width="150" height="150" src="assets/img/icon-coin.png">
              <h4>5x günstiger</h4>
              <p class="large">
                Durchschnittlich 5x geringere cost-per-hire bieten dir die Möglichkeit,
                deine Recruiting-Kosten deutlich zu reduzieren.
              </p>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>

  <div class="row align-items-center justify-content-center mt-5">
    <div class="col-auto">
      <button  scAnchorLink="book" anchorReferencePosition="middle" anchorTargetPosition="middle" class="btn btn-lg btn-round btn-primary">Jetzt Demo buchen</button>
    </div>
  </div>

</section>
