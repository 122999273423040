import { Component, OnInit, Input } from '@angular/core';
import { ScScrollService } from '@sc-ui';

@Component({
  selector: 'app-job-check-header',
  templateUrl: './job-check-header.component.html',
  styleUrls: ['./job-check-header.component.scss']
})
export class JobCheckHeaderComponent {

  @Input()
  home = 'home';
  
  scrolled = false;
  
  constructor(scrollService: ScScrollService) {

    scrollService.$scrollYChange.subscribe(y => {
      this.scrolled = y > 400;
    });

    this.scrolled = scrollService.scrollY > 400;
  }

  ngOnInit(): void {}

}
