import { Component, OnInit } from '@angular/core';
import { model } from './model';
import { MetaTagsService } from 'src/services/meta-tags.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent implements OnInit {

  model = model;

  constructor(private meta : MetaTagsService) { }

  ngOnInit() {
    this.meta.addMetaTags(this.model);
  }

}

