import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-blob3',
  templateUrl: './svg-blob3.component.html',
  styleUrls: ['./svg-blob3.component.scss']
})
export class SvgBlob3Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
