import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ebner-stolz-how-it-works-step',
  templateUrl: './ebner-stolz-how-it-works-step.component.html',
  styleUrls: ['./ebner-stolz-how-it-works-step.component.scss']
})
export class EbnerStolzHowItWorksStepComponent implements OnInit {

  @Input()
  number;

  @Input()
  headline;

  @Input()
  image;
  
  constructor() { }

  ngOnInit(): void {
  }

}
