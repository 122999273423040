import { Component, ViewChild, ElementRef, OnDestroy, Inject, PLATFORM_ID, AfterViewInit, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-job-check-cta',
  templateUrl: './job-check-cta.component.html',
  styleUrls: ['./job-check-cta.component.scss']
})
export class JobCheckCtaComponent implements AfterViewInit, OnDestroy {


  @ViewChild("scrollCta", { static: true })
  scrollCta: ElementRef;

  @Input()
  ctaUrl = '/stellencheck';

  isBrowser = false;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngAfterViewInit(): void {
    if (!this.isBrowser) return;
    setTimeout(() => { this.createAnimations(); }, 0);
  }

  createAnimations() {

    if (!this.isBrowser) return;

    gsap.registerPlugin(ScrollTrigger);

    let scrollCtaEl = this.scrollCta.nativeElement;

    let mm = gsap.matchMedia();

    mm.add("(max-width: 779px)", () => {

      let ctaCardAnim = gsap.timeline({
        paused: true
      });

      ctaCardAnim

        .from('.job-check-card', {
          scale: 1.1,
          yPercent: 30
        })

      ScrollTrigger.create({
        trigger: scrollCtaEl,
        id: 'card-anim',
        start: "top bottom",
        end: "bottom bottom",
        anticipatePin: 1,
        scrub: .3,
        markers: false,
        animation: ctaCardAnim,
      })


      ScrollTrigger.create({
        trigger: scrollCtaEl,
        id: "bg-anim",
        start: "bottom top",        
        end: "+=110%",
        // anticipatePin: 1,
        // markers: true,
        onEnter: () => document.body.classList.add('bg-primary-lighter'),
        onLeave: () => document.body.classList.remove('bg-primary-lighter'),
        onEnterBack: () => document.body.classList.add('bg-primary-lighter'),
        onLeaveBack: () => document.body.classList.remove('bg-primary-lighter'),
        toggleActions: "play reverse resume reset",
      });


    }),


      mm.add("(min-width: 780px)", () => {

        // let ctaCardAnim = gsap.timeline({
        //   paused: true
        // });

        // ctaCardAnim
        //   .from('.job-check-card', {
        //     scale: 1.1,
        //     yPercent: 100
        //   })

        // ScrollTrigger.create({
        //   trigger: scrollCtaEl,
        //   id: 'card-anim',
        //   start: "top top",
        //   end: "bottom bottom-=5%",
        //   anticipatePin: 1,
        //   scrub: 1,
        //   markers: false,
        //   animation: ctaCardAnim,
        // })


        ScrollTrigger.create({
          trigger: scrollCtaEl,
          id: "bg-anim",
          start: () => `top+=150 bottom`,
          end: "+=104%",
          // anticipatePin: 1,
          // pin: '.cta-container',
          // markers: false,
          onEnter: () => document.body.classList.add('bg-primary-lighter'),
          onLeave: () => document.body.classList.remove('bg-primary-lighter'),
          onEnterBack: () => document.body.classList.add('bg-primary-lighter'),
          onLeaveBack: () => document.body.classList.remove('bg-primary-lighter'),
          toggleActions: "play reverse resume reset",
        });
      });
  }

  ngOnDestroy(): void {

    if (!this.isBrowser) return;

    document.body.classList.remove('bg-primary-lighter');

    let triggers = ScrollTrigger.getAll();
    triggers.forEach(trigger => {
      trigger.kill(true);
    });

    ScrollTrigger.refresh();


  }
}
