import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, NavigationStart } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { BodyService, ScApiService, ScScrollService } from '@sc-ui';
import { isPlatformBrowser, Location } from '@angular/common';
import { routerTransition } from './router.animations';
import { environment } from './environment.prod';
import { global } from './global';
import { GoogleAnalyticsService } from '../refs/sc-ui/services/google-analytics.service';
import { TracingService } from './services/tracing.service';
import { LinkedInTrackingService } from './services/linked-in-tracking.service';

declare var device;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition]
})
export class AppComponent implements OnInit, AfterViewInit {

  title = 'skillconomy';

  g: any = global;

  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private api: ScApiService,
    private scrollService : ScScrollService,
    private googleAnalytics: GoogleAnalyticsService,
    private tracing : TracingService,
    private linkedInTracking : LinkedInTrackingService,
    @Inject(PLATFORM_ID) private platformId: string) { }

  ngOnInit(): void {    

    if (!isPlatformBrowser(this.platformId)) return;
    
    this.tracing.init();
    this.linkedInTracking.init();

    this.location.subscribe((ev: any) => {
      console.log(ev);
      this.lastPoppedUrl = ev.url;
      
    });

    this.router.events.subscribe((ev: any) => {

      if (ev instanceof NavigationStart) {
        if (ev.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      }

      if (ev instanceof NavigationEnd) {
        
        this.linkedInTracking.track();

        const urlTree = this.router.parseUrl(this.router.url);        

        if (urlTree.fragment) {
          console.log(urlTree.fragment);
          setTimeout(() => this.scrollService.scrollTo(urlTree.fragment), 100);
          // var target = document.querySelector('#' + urlTree.fragment);
          // if (target != null) {
          //   setTimeout(() => target.scrollIntoView({behavior: 'smooth'}), 100);
          // }
        }

        else if (ev.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          var scrollY = this.yScrollStack.pop();
          setTimeout(() => window.scrollTo(0, scrollY), 500);
        } 
        else
        {
          window.scrollTo(0, 0);
        }
      }
    });

    this.route.queryParams.subscribe(params => {

      if (params.t) {
        var trace: any = {
          TraceId: params.t
        };

        if (params.u) {
          trace.ContactId = params.u;
        }

        this.api.post('trace', trace).subscribe();
      }
    });
  }

  ngAfterViewInit() {

    if (!isPlatformBrowser(this.platformId)) return;

    this.adjustViewHeightCSSVar();

    window.addEventListener('resize', () => {
      this.adjustViewHeightCSSVar();
    });

    window.scrollTo(100, 0);
  }

  private adjustViewHeightCSSVar() {

    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

}
