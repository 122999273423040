<section class="sc-hero-start">
  <div class="container">
    <div class="row align-items-end">
      <div class="col-12 col-lg-7">
        <div class="row">
          <div class="col-12">
            <h1 class="heading-xl heading-serif">Du bist eingeladen<br>von RSM Ebner Stolz!</h1>
          </div>

          <div class="col-6 offset-3 col-sm-4 offset-sm-4 col-md-4 offset-md-4 col-lg-4 offset-lg-0 mb-6 mt-6">
            <img src="assets/svg/client-logos/logo-rsm-ebner-stolz.svg" class="img-fluid">
          </div>

          <div class="col-12 col-sm-6 col-lg-7 offset-lg-1 mt-lg-6">
            <p *ngIf="variant == 'business'" class="larger">
              Lerne RSM Ebner Stolz exklusiv über skillconomy kennen - per Chatbot, bei Events oder im persönlichen Kontakt: du entscheidest!
              <br><br>
              RSM Ebner Stolz ist eine multidisziplinäre Prüfungs- und Beratungsgesellschaft und zählt zu den TOP 10 in Deutschland.
            </p>
            <p *ngIf="variant == 'it'" class="larger">
              Die mittelständische Prüfungs- und Beratungsgesellschaft mit stark wachsender
              IT-Revision und einer Vielzahl spannender Digitalisierungsthemen zählt zu
              den TOP 10 Unternehmensberatungen in Deutschland – und möchte dich exklusiv zu
              skillconomy einladen.
            </p>
          </div>

        </div>
      </div>
      <div class="col-lg-5">
        <div class="row mt-6 mt-lg-0">
          <div class="col-5 offset-1 col-md-9 offset-md-0 col-lg-6 text-right">
            <ul id="benefits-target" class="benefits list-unstyled text-center" scHeroListcycler>

              <li #cycleItems>
                <div>Deine <br>Ziele <br>zählen</div>
              </li>

              <li #cycleItems>
                <div>Exklusive <br>Insights</div>
              </li>

            </ul>
          </div>
          <div class="col-5 col-md-3 col-lg-6"><img src="assets/svg/sc-bot-magnifying-glass.svg" class="img-fluid illu"></div>
        </div>
      </div>
    </div>
    <app-svg-blob1></app-svg-blob1>
  </div>
</section>
