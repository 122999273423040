<section>
  <div class="container">
    <div class="col-12 col-lg-10 offset-lg-1 mb-6">
      <div class="video-container ratio ratio-16x9">
        <video controls poster="/assets/video/ebner-stolz-poster.jpg">
          <source src="https://screcruiting.blob.core.windows.net/publicassets/ebner-stolz.mp4" type="video/mp4">
          Ihr Browser unterstützt den HTML5 Video Tag nicht.
        </video>
      </div>
    </div>
  </div>
</section>



