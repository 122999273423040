<section class="sc-company-teaser">
    <div class="container">
        <div class="row align-items-lg-center">

            <div class="order-2 col-12 col-md-6 order-md-2 offset-md-1 col-lg-5 offset-lg-1">

                <p class="large">Unternehmen</p>

                <h2>Innovationen im Recruiting<br>- seit über 10 Jahren</h2>

                <p class="larger mb-4 mb-lg-5">
                  Alles, was wir tun, zielt darauf ab, gute Beziehungen zu schaffen. Egal ob in unserem Team oder für unsere Kandidat:innen und
                  Kund:innen. Dabei entwicklen wir eigene Technologien und Systeme, die Innovationen für das Recruiting schaffen: 
                  Vom ersten Jobsite-Chatbot im Jahr 2014 bis zur eigenen Recruiting-KI zehn Jahre später. 
                </p>

                <a class="link-arrow right text-uppercase" routerLink="/unternehmen">Mehr erfahren</a>

            </div>
            
            <div class="col-12 col-sm-10 offset-sm-1 mt-6 col-md-5 offset-md-0 order-1 order-md-1 mt-md-0 col-lg-5 mb-5 mb-md-0">

                <picture>                    
                    <img width="1048" height="1262" src="assets/img/home-unternehmen.jpg" alt="skillconomy Unternehmen" class="img-fluid">
                </picture>

            </div>

        </div>
    </div>
</section>