import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image-reveal',
  templateUrl: './image-reveal.component.html',
  styleUrls: ['./image-reveal.component.scss']
})
export class ImageRevealComponent implements OnChanges, AfterViewInit {

  @ViewChild('imgWrapper')
  imgWrapper: ElementRef

  @Input()
  src: any = ''

  @Input()
  revealDelay = 500;

  isRevealed = true;

  isInited = false;

  ngAfterViewInit() {
    this.loadImage();
    this.isInited = true;
  }

  ngOnChanges() {
    if (!this.isInited) return;
    this.loadImage();
  }

  loadImage() {
    if (this.src?.length > 0) {
      this.imgWrapper.nativeElement.style.backgroundImage = `url('${this.src}')`;
      if (!this.isRevealed) {
        this.reveal();
      }
    }
    else {
      this.isRevealed = false;
    }
  }

  reveal(animate = true) {
    if (animate) {
      setTimeout(() => { this.isRevealed = true; }, this.revealDelay);
    }
    else
    {
      this.isRevealed = true;
    }
  }

}
