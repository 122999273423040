import { Directive, ElementRef, QueryList, ViewChildren, AfterViewInit, ContentChildren } from '@angular/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Directive({
  selector: '[scHeroListcycler]'
})
export class HeroListcyclerDirective {

  @ContentChildren('cycleItems') lis: QueryList<ElementRef>;
  slides: Array<ElementRef> = new Array;
  tl: any;

  constructor(private host: ElementRef) { 

  }

  ngAfterContentInit(){

    this.tl = gsap.timeline();

    this.lis.changes.subscribe( () => {
      this.initListCycling();
    })
   
    this.slides.forEach((div: ElementRef) => console.log(div.nativeElement));
    this.initListCycling();
    
  }

  initListCycling() {

    this.slides = new Array;
    this.lis.forEach((li: ElementRef) => this.slides.push(li.nativeElement));
    this.tl.clear();
    this.tl.set(this.slides, { y: -10, scale: .8 })
    this.tl.to(this.slides, .25, {autoAlpha: 1, scale: 1, y: 0, stagger: 2.5, repeat: -1, repeatDelay: 2.5})

  }

}
