export const global = {
  "title": "skillconomy | Active Sourcing. Das Original",
  "description": null,
  "keywords": [
    "eins",
    "zwei",
    "drei"
  ],
  "nav": [
    {
      "url": "/candidate-experience",
      "text": "Candidate Experience"
    },
    {
      "url": "/unternehmen", 
      "text": "Unternehmen"
    },
    {
      "url": "/blog", 
      "text": "Blog"
    },
    {
      "url": "/kontakt",
      "text": "Kontakt"
    }
  ],
  "metanav": [
    {
      "url": "/impressum",
      "text": "Impressum"
    },
    {
      "url": "/datenschutz",
      "text": "Datenschutz"
    }
  ],
  "copyright": "skillconomy",
  "contact": {
     "companyname": "skillconomy GmbH",
     "country": "Deutschland",
     "headline": "Kontaktieren <br>Sie uns!",
     "phone": "+49 2171 - 77 67 67 6",
     "email": "jonas@skillconomy.com",
     "street": "Werkstättenstraße",
     "housenumber": 18,
     "zip": 51379,
     "city": "Leverkusen",
     "maplink": "https://www.google.com/maps/dir//Werkst%C3%A4ttenstra%C3%9Fe+18+51379+Leverkusen/@51.0560696,7.022122,11.6z/data=!4m8!4m7!1m0!1m5!1m1!1s0x47bf2c10bf923c15:0x1662f69d8c8cd906!2m2!1d7.0101414!2d51.0649238",
     "chatbotid": "226bb7ff-50ce-4b2f-afae-69b2a6eb65b4"
  }
}