<section>

  <div class="container-fluid cta-container">

    <div class="container">

      <div class="row align-items-lg-center">

        <div class="col-12 col-md-12 offset-md-0 col-lg-12 offset-lg-0" #scrollCta>
          <app-job-check-cta-card [ctaUrl]="ctaUrl"></app-job-check-cta-card>
        </div>

      </div>

    </div>
  </div>

</section>
