import { Component } from '@angular/core';
import { BlogService } from 'src/services/blog.service';

@Component({
  selector: 'app-home-blog',
  templateUrl: './home-blog.component.html',
  styleUrls: ['./home-blog.component.scss']
})
export class HomeBlogComponent {

  posts : any = [];

  constructor(private blogService : BlogService)
  {

  }

  ngOnInit()
  {
    this.posts = this.blogService.posts.slice(0, 3);
  }

}
