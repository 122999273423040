import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-lp-button',
  templateUrl: './lp-button.component.html',
  styleUrls: ['./lp-button.component.scss']
})
export class LpButtonComponent implements OnInit {

  @Input()
  type = 'primary';

  constructor() { }

  ngOnInit() {
  }

}