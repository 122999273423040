<section class="sc-cta pt-6">
    <div class="container">
        <div class="row d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column">
                <button class="btn btn-primary btn-round btn-lg sc-cta-btn" (click)="clicked.next()">
                    Jetzt starten!
                </button>
            </div>
        </div>
    </div>
</section>