import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AgmCoreModule } from '@agm/core';

import { ScChatbotStreamComponent } from './components/stream/stream.component';
import { ScChatbotInputComponent } from './components/input/input.component';
import { ScChatbotMapComponent } from './components/input-map/input-map.component';
import { ScChatbotInputChoiceComponent } from './components/input-choice/input-choice.component';
import { ScChatbotInputYesNoComponent } from './components/input-yes-no/input-yes-no.component';
import { ScChatbotTextinputComponent } from './components/input-text/input-text.component';
import { ScChatbotMessageloaderComponent } from './components/messageloader/messageloader.component';
import { ScChatbotInputFileUploadComponent } from './components/input-file-upload/input-file-upload.component';

import { ScUiModule } from '@sc-ui';
import { ScChatbotAnswerComponent } from './components/answer/answer.component';
import { ScChatbotAnswerChoiceComponent } from './components/answer-choice/answer-choice.component';
import { ScChatbotAnswerFileUploadComponent } from './components/answer-file-upload/answer-file-upload.component';
import { ScChatbotAnswerMapComponent } from './components/answer-map/answer-map.component';
import { ScChatbotInputNoneComponent } from './components/input-none/input-none.component';
import { ScChatbotHeaderComponent } from './components/header/header.component';
import { RouterModule } from '@angular/router';
import { ScChatbotAnswerTextComponent } from './components/answer-text/answer-text.component';
import { ScChatbotOverlayComponent } from './components/overlay/overlay.component';
import { ScChatbotTriggerButtonComponent } from './components/trigger-button/trigger-button.component';
import { IntelligenceTriggerButtonComponent } from './components/intelligence-trigger-button/intelligence-trigger-button.component';
import { ScChatbotIntelligenceOverlayComponent } from './components/intelligence-overlay/intelligence-overlay.component';
import { OverlayMultiChatComponent } from './components/overlay-multi-chat/overlay-multi-chat.component';
import { ChatListComponent } from './components/chat-list/chat-list.component';
import { ChatDateComponent } from './components/chat-date/chat-date.component';
import { ChatHeaderComponent } from './components/chat-header/chat-header.component';
import { ChatListHeaderComponent } from './components/chat-list-header/chat-list-header.component';
import { AnimateHeightDirective } from './directives/animate-height.directive';
import { MessageScrollContainerComponent } from './components/message-scroll-container/message-scroll-container.component';
import { InputDefaultComponent } from './components/input-default/input-default.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ScUiModule,
        // FontAwesomeModule,
        BrowserAnimationsModule,
        RouterModule
    ],
    declarations: [
        ScChatbotStreamComponent,
        ScChatbotInputComponent,
        ScChatbotMapComponent,
        ScChatbotInputChoiceComponent,
        ScChatbotInputYesNoComponent,
        ScChatbotTextinputComponent,
        ScChatbotMessageloaderComponent,
        ScChatbotInputFileUploadComponent,
        ScChatbotAnswerComponent,
        ScChatbotAnswerChoiceComponent,
        ScChatbotAnswerFileUploadComponent,
        ScChatbotAnswerMapComponent,
        ScChatbotInputNoneComponent,
        ScChatbotHeaderComponent,
        ScChatbotAnswerTextComponent,
        ScChatbotOverlayComponent,
        ScChatbotTriggerButtonComponent,
        IntelligenceTriggerButtonComponent,
        ScChatbotIntelligenceOverlayComponent,
        OverlayMultiChatComponent,
        ChatListComponent,
        ChatDateComponent,
        ChatHeaderComponent,
        ChatListHeaderComponent,
        AnimateHeightDirective,
        MessageScrollContainerComponent,
        InputDefaultComponent
    ],
    exports: [
        ScChatbotStreamComponent,
        ScChatbotOverlayComponent,
        ScChatbotTriggerButtonComponent,
        ScChatbotIntelligenceOverlayComponent,
        IntelligenceTriggerButtonComponent,
        OverlayMultiChatComponent,
        ChatListComponent,
        ChatHeaderComponent,
        ChatListHeaderComponent,
        AnimateHeightDirective,
        ScChatbotInputComponent
    ]
})
export class ScChatbotModule {
  constructor() {
    // library.add(farFileUpload);
    // library.add(fasAngleUp);
    // library.add(fasEnvelope);
    // library.add(fasMobileAlt);
    // library.add(fasUser);
    // library.add(fasCheck);
    // library.add(farChevronDown);
  }
}
