import { AfterViewInit, Component, Inject, PLATFORM_ID, QueryList, ViewChildren } from '@angular/core';
import { JobCheckStepBaseComponent } from '../../components/job-check-step-base/job-check-step-base.component';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

const LOCAL_STORAGE_KEY = "feedback-data";

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements AfterViewInit {

  secret: any;

  data: any = {};

  stepIndex = 0;

  nextTargetIndex = 0;

  @ViewChildren('step')
  stepComponents: QueryList<JobCheckStepBaseComponent>;

  isBrowser = false;

  constructor(private route: ActivatedRoute, @Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId); 
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.secret = params.secret;
    });
  }

  ngAfterViewInit(): void {

    if(!this.isBrowser) return;

    this.data = {
      secret: this.secret
    };

    this.stepIndex = 0;

    this.loadState();

    this.stepComponents.forEach((c, i) => {

      c.data = this.data;
      c.active = i == this.stepIndex;
      c.initialize();

      c.back.subscribe(() => {
        this.back(c);
      });

      c.change.subscribe(() => {
        this.storeState();
      });

      c.complete.subscribe(() => {
        this.next(c);
      });

      c.restart.subscribe(() => {
        this.restart();
      });

      c.navigate.subscribe((index) => {
        this.goTo(index);
      })
    });
  }

  back(currentStep) {

    this.nextTargetIndex = 0;

    var stepComponents = this.stepComponents.toArray();

    while (true) {
      this.stepIndex--;
      if (this.stepIndex < 0) return;;

      var nextStep = stepComponents[this.stepIndex];
      if (!nextStep.checkSkip()) {
        currentStep.active = false;
        nextStep.active = true;
        this.storeState();
        break;
      }
    }
  }

  next(currentStep) {

    if (this.nextTargetIndex) {
      this.goTo(this.nextTargetIndex);
      this.nextTargetIndex = 0;
      return;
    }

    var stepComponents = this.stepComponents.toArray();

    while (true) {
      this.stepIndex++;

      if (this.stepIndex >= stepComponents.length) return;

      var nextStep = stepComponents[this.stepIndex];

      if (nextStep.checkSkip()) continue;

      currentStep.active = false;
      nextStep.active = true;

      var isLastStep = this.stepIndex == stepComponents.length - 1;
      if (isLastStep) {
        this.resetState();
      }
      else {
        this.storeState();
      }

      break;
    }
  }

  goTo(index) {

    var stepComponents = this.stepComponents.toArray();

    if (index >= stepComponents.length) return;
    if (index < 0) return;

    var nextStep = stepComponents[index];
    if (nextStep.checkSkip()) return;

    this.nextTargetIndex = this.stepIndex;
    this.stepIndex = index;

    this.stepComponents.forEach((c, i) => {
      c.active = i == this.stepIndex;
    });

    this.storeState();
  }

  resetState() {
    localStorage.removeItem(LOCAL_STORAGE_KEY);
  }

  restart() {

    this.data = {
      secret: this.secret
    };

    this.stepIndex = 0;
    this.nextTargetIndex = 0;

    this.stepComponents.forEach((c, i) => {
      c.data = this.data;
      c.active = i == this.stepIndex;
      c.initialize();
    });

    this.storeState();
  }

  storeState() {
    var state = {
      data: this.data,
      stepIndex: this.stepIndex,
      nextTargetIndex: this.nextTargetIndex
    };

    var stateJson = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_KEY, stateJson);
  }

  loadState() {
    var stateJson = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (stateJson) {
      var state = JSON.parse(stateJson);
      this.data = state.data;
      this.stepIndex = state.stepIndex ?? 0;
      this.nextTargetIndex = state.nextTargetIndex ?? 0;


      if (!this.data || this.data.secret != this.secret) {
        this.resetState();
        this.restart();
      }
    }
  }
}
