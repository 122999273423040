
<section class="sc-contentblock">
<div class="container">
    <div class="row mt-5">
        <div class="col">
            <h3 *ngIf="!unsubscribed">Einen Moment bitte...</h3>
            <h3 *ngIf="unsubscribed">Wir haben Sie von unserem Newsletter abgemeldet.</h3>
        </div>
    </div>
</div>
</section>

<app-contact-form></app-contact-form>