<div class="sc-slider">
    <div #slider>
        <ng-content></ng-content>
    </div>

    <nav *ngIf="slideCount > 1">
        <span inlineSVG="assets/svg/circle-arrow-left.svg" class="prev" (click)="prev()"></span>
        <span inlineSVG="assets/svg/circle-arrow-right.svg" class="next" (click)="next()"></span>
    </nav>

    <div class="pagination" *ngIf="showPagination">
        <div class="row justify-content-{{paginationAlignment}}">
            <ul class="col-auto pagination-list list-unstyled">
                <li *ngFor="let i of indices" [class.is-active]="i == currentIndex" (click)="goto(i)"></li>
            </ul>
        </div>
    </div>
</div>