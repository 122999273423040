import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-paper-plane',
  templateUrl: './svg-paper-plane.component.html',
  styleUrls: ['./svg-paper-plane.component.scss']
})
export class SvgPaperPlaneComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
