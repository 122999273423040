import { isPlatformBrowser } from '@angular/common';
import { Component, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ScScrollService } from '@sc-ui';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { gsap } from 'gsap';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  scrolled = false;
  
  constructor(scrollService: ScScrollService, @Inject(PLATFORM_ID) private platformId: string, private router : Router) {

    scrollService.$scrollYChange.subscribe(y => {
      this.scrolled = y > 100;
    });

    this.scrolled = scrollService.scrollY > 100;
  }

  navAnimation;
  
  hamburgerAnimation;

  isVisible = true;

  ctaTarget : any = null;


  @ViewChild("nav", { static: true })
  nav: ElementRef;

  ngOnInit() {
    this.router.events.subscribe((ev: any) => {      
      if (ev instanceof NavigationEnd) {
        if (!isPlatformBrowser(this.platformId)) return;        
        console.log('nav end');
      }
    });
  }

  ctaClick()
  {
    // this.router.navigateByUrl('/stellencheck-intro');

    var ctaTarget = document.querySelector('app-job-check-cta');
    if (ctaTarget != null)
    {
      ctaTarget.scrollIntoView({behavior: "smooth"});
    }
    else
    {
      
    }
  }

  ngAfterViewInit(): void {

    if (!isPlatformBrowser(this.platformId)) return;

    var navEl = this.nav.nativeElement;
    var trigger = navEl.querySelector('#sc-nav-toggle');

    var mainNav = navEl.querySelector('#sc-main-nav');
    var mainNavItems = navEl.querySelectorAll('#sc-main-nav li');
    var bubble = navEl.querySelector('.sc-nav .bubble');

    this.navAnimation = gsap.timeline();
    this.hamburgerAnimation = gsap.timeline();

    gsap.set(mainNavItems, { x: 80, opacity: 0 });
    gsap.set(bubble, { xPercent: 110, yPercent: 30, scale: .5 });

    this.hamburgerAnimation.to("#bar-top", { y: 7.12, duration: .25, ease: "sine.out" })
      .to("#bar-bottom", { y: -7.44, duration: .25, ease: "sine.out" }, "-=.25")
      .to("#bar-bottom", { opacity: 0, duration: 0 })
      .to("#bar-top", { rotation: 45, transformOrigin: "center", duration: .25, ease: "sine.in" })
      .to("#bar-mid", { rotation: -45, transformOrigin: "center", duration: .25, ease: "sine.in" }, "-=.25")
      .reverse();

    this.navAnimation
      .set(mainNav, { xPercent: -100 })
      .to(mainNav, { autoAlpha: 1, duration: .4, ease: "Sine.easeOut" }, 0)
      .to(bubble, { duration: 1.2, xPercent: 0, yPercent: 0, ease: 'Back.easeInOut', scale: 1 }, "-=.6")
      .to(mainNavItems, {
        autoAlpha: 1,
        duration: .4,
        ease: "power1.out",
        stagger: 0.2,
        x: 0
      }, 0.65)
      .reverse();

    trigger.addEventListener('click', () => {

      if (this.navAnimation.reversed()) {
        setTimeout(() => {
          disableBodyScroll(mainNav, {
            reserveScrollBarGap: true,
            allowTouchMove: false
          });
        }, 0);
      } else {
        clearAllBodyScrollLocks();
      }


      this.navAnimation.reversed(!this.navAnimation.reversed());
      this.hamburgerAnimation.reversed(!this.hamburgerAnimation.reversed());
    });

  }

  openPortal()
  {
    window.open('https://app.skillconomy.com?t=af','_blank');
  }

  navItemClick() {
    this.navAnimation.reversed(true);
    this.hamburgerAnimation.reversed(true);
    clearAllBodyScrollLocks();
  }

}
