import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.scss']
})
export class TopicsComponent implements OnInit {

  items: any;

  constructor(private router: Router) { }

  ngOnInit(): void {
    var mainRoutes = this.router.config[0].children;
    var topicRoute = mainRoutes.filter(i => i.path == 'themen')[0];
    var topics = topicRoute.children;
    this.items = topics;
  }

}
