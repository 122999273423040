import { isPlatformBrowser } from '@angular/common';
import { Component, ViewChild, ElementRef, OnDestroy, Inject, PLATFORM_ID, AfterViewInit } from '@angular/core';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

@Component({
  selector: 'app-home-usps',
  templateUrl: './home-usps.component.html',
  styleUrls: ['./home-usps.component.scss']
})
export class HomeUspsComponent implements AfterViewInit, OnDestroy {

  private _scrollTriggers: any = [];

  activeSection = 0;

  isPinned = false;

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  @ViewChild("howItWorks", { static: true })
  howItWorks: ElementRef;

  ngAfterViewInit(): void {
    setTimeout(() => { this.createAnimations(); }, 0);    
  }

  createAnimations()
  {
    if (!isPlatformBrowser(this.platformId)) return;
    
    gsap.registerPlugin(ScrollTrigger);

    const media = window.matchMedia("screen and (min-width: 600px)");
    if (!media.matches) return;

    var section = this.howItWorks.nativeElement;
    var pinnedContent = null;

    this._scrollTriggers.push(ScrollTrigger.create({
      trigger: ".trigger",
      start: "top 69px",
      end: "bottom center",
      pin: ".pin",
      pinSpacing: false,
      onEnter: () => this.isPinned = true,
      onLeave: () => this.isPinned = false,
      onEnterBack: () => this.isPinned = true,
      onLeaveBack: () => this.isPinned = false
    }));

    this._scrollTriggers.push(ScrollTrigger.create({
      trigger: section,
      start: "top top",
      end: "bottom bottom",
      pin: ".col-right",
      pinSpacing: false,
      markers: false
    }));

    const contentTriggers = gsap.utils.toArray(".content-marker");

    var animateIn = (newPinnedContent: any) => {
      if (pinnedContent == newPinnedContent) return;
      gsap.to(pinnedContent, { duration: 0.1, autoAlpha: 0 });
      gsap.fromTo(newPinnedContent, { autoAlpha: 0, rotateY: -30 }, { duration: 0.5, autoAlpha: 1, rotateY: 0 });
      pinnedContent = newPinnedContent;
    };

    contentTriggers.forEach((trigger: any) => {

      var i = contentTriggers.indexOf(trigger);

      var triggeredContent = document.querySelector(`#${trigger.dataset.markerContent}`);

      if (!pinnedContent) pinnedContent = triggeredContent;

      gsap.set(triggeredContent, { transformOrigin: "center" });

      this._scrollTriggers.push(ScrollTrigger.create({
        trigger: trigger,
        start: "10% bottom",
        onEnter: () => {
          animateIn(triggeredContent);
          this.activeSection = i;
        }
      }));

      this._scrollTriggers.push(ScrollTrigger.create({
        trigger: trigger,
        start: "10% top",
        onEnterBack: () => {
          animateIn(triggeredContent);
          this.activeSection = i;
        }
      }));
    });
  }

  ngOnDestroy() {
    this._scrollTriggers.forEach(s => s.kill(true));
    ScrollTrigger.refresh();
  }
}
