<app-job-check-header home="home"></app-job-check-header>

<svg class="blob" preserveAspectRatio="xMidYMax slice" viewBox="0 0 2155 846" xmlns="http://www.w3.org/2000/svg">
  <path d="m1191.3615 8.99009608c179.46072-16.8766341 326.14441-13.66197326 461.28581 41.76376842 100.15004 41.0744336 177.12103 99.0858515 242.95287 164.6356965 110.83204 110.380751 191.32318 229.634341 222.6478 363.854679 14.79046 63.323997 41.86408 173.723998 35.30144 265.707399l-2153.54942 2.33225c5.67652474-179.964518 79.8652714-347.311189 217.501604-501.793772 95.947064-107.69315 235.551844-180.729117 404.604463-224.294008 112.610447-29.0204612 228.891506-51.4855357 344.590051-74.2791842 83.536802-16.4541818 168.819232-28.6194669 224.665382-37.92682872z" fill="#eef4fb" fill-rule="evenodd" transform="matrix(-1 0 0 -1 2154.64852 846.000001)" />
</svg>


<main>

<div class="container">

  <div class="row justify-content-md-center">

    <div *ngIf="isBrowser" class="col-md-8 col-lg-8 px-lg-5 pb-6 pt-6">

      <!-- <div class="mb-6 mt-6 job-check-header d-none d-md-block">
        <h1 class="heading-serif heading-lg mb-2">Ihr Feedback</h1>
      </div> -->

      <app-feedback-step-one #step></app-feedback-step-one>
	  <app-feedback-step-two #step></app-feedback-step-two>
	  <app-feedback-step-three #step></app-feedback-step-three>
	  <app-feedback-step-four #step></app-feedback-step-four>
	  <app-feedback-step-five #step></app-feedback-step-five>
	  <app-feedback-step-success #step></app-feedback-step-success>

      <div *ngIf="stepIndex > 0 && stepIndex != 5" class="mt-4">
        <a class="text-muted clickable small" (click)="restartModal.show()"><i class="fa-solid fa-rotate-right me-2"></i>Feedback neu starten</a>
      </div>

    </div>



    <div class="bot-illu col-2 d-none d-lg-block">

      <img *ngIf="stepIndex != stepComponents?.length - 1" src="assets/svg/sc-illu-bot-magnifying-glass.svg" alt="skillconomy Illu Bot" class="img-fluid">
      <!-- <img *ngIf="stepIndex == stepComponents?.length - 1" src="assets/svg/sc-illu-bot-presenting.svg" alt="skillconomy Illu Bot" class="img-fluid"> -->

    </div>

  </div>
</div>

</main>

<sc-modal size="sm" #restartModal>
  <sc-modal-header>
    Neu starten?
  </sc-modal-header>
  <sc-modal-body>
    Ihr bisherigen Eingaben gehen verloren. Möchten Sie wirklich neu beginnen?
  </sc-modal-body>
  <sc-modal-footer>
    <button class="btn btn-secondary btn-round" (click)="restartModal.dismiss();">Doch nicht</button>
    <button class="btn btn-primary btn-round" (click)="restart(); restartModal.dismiss();">Ja</button>
  </sc-modal-footer>
</sc-modal>
