<section>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <h2 class="heading-xl heading-serif">So funktioniert's</h2>
      </div>
    </div>
  </div>

  <div class="container">

    <div class="row">
      <div class="col-12 col-sm-10 offset-sm-1 col-md-12 offset-md-0">
        <div scAnchorTarget="1" class="row step align-items-center mt-6">
          <div class="col-12 col-md-5 col-lg-5 offset-lg-1 mb-6 mb-md-0">
            <h3>1. Suchprofil definieren</h3>
            <p class="large">
              Mit KI und Know-how überführen wir deine Anforderungen in ein optimiertes Suchprofil. So findet die KI alle relevanten 
              Kandidat:innen in beruflichen Netzwerken wie LinkedIN und XING und in unserem eigenen Talent-Pool.
            </p>
          </div>

          <div class="col-10 offset-1  col-md-6 offset-md-1 col-lg-5 offset-lg-1">
            <img src="assets/img/briefingchat2.png" width="1051" height="1051" class="w-100 img-fluid box-shadow" />
          </div>
        </div>

        <div scAnchorTarget="2" class="row step align-items-center mt-7">
          <div class="col-12 col-md-5 offset-md-1 order-md-2 col-lg-5 mb-6 mb-md-0">
            <h3>2. Kandidat:innen einladen</h3>
            <p class="large">
              Mit der besten Candidate Experience am Markt begeistern wir mehr Bewerber:innen für deine Stelle. In unserem Web-Portal siehst du die 
              qualifizierten Profile und kannst entscheiden, wen du zu einem Kennenlernen einladen möchtest.
            </p>
          </div>

          <div class="col-10 offset-1 col-md-6 offset-md-0 col-lg-5 offset-lg-1">
            <img src="assets/img/product-how-it-works-4.png" width="1378" height="1459" class="w-100 img-fluid img-step-2 mb-5" />
          </div>
        </div>

        <div scAnchorTarget="3" class="row step align-items-center mt-7">
          <div class="col-12 col-md-5 col-lg-5 offset-lg-1 mb-6 mb-md-0">
            <h3>3. Stelle besetzen</h3>
            <p class="large">
              Wir reduzieren deinen Aufwand auf fünf Minuten pro Stelle, damit du Zeit für das Wichtigste hast: <br>Im persönlichen Austausch das perfekte neue Teammitglied finden, begeistern und einstellen. 
            </p>
          </div>

          <div class="col-10 offset-1 col-md-6 offset-md-1 col-lg-5 offset-lg-1 px-md-6 ">
            <img src="assets/img/hire.jpg" width="1051" height="1051" class="w-100 img-fluid" />
          </div>
        </div>

      </div>
    </div>
  </div>

</section>
