import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'app-job-check-step-base',
  templateUrl: './job-check-step-base.component.html',
  styleUrls: ['./job-check-step-base.component.scss']
})
export class JobCheckStepBaseComponent {
 
  change = new EventEmitter<any>();

  complete = new EventEmitter<any>();

  back = new EventEmitter<any>();
  
  restart = new EventEmitter<any>();

  navigate = new EventEmitter<any>();
  
  active = false;
  
  data : any;

  initialize() {}

  checkSkip() { return false; }

  navigateToStep(index)
  {
    this.navigate.next(index);
  }
}
