import { Component } from '@angular/core';
import { JobCheckStepBaseComponent } from 'src/components/job-check-step-base/job-check-step-base.component';

@Component({
  selector: 'app-feedback-step-one',
  templateUrl: './feedback-step-one.component.html',
  styleUrls: ['./feedback-step-one.component.scss']
})
export class FeedbackStepOneComponent extends JobCheckStepBaseComponent {

  options = [
    {
      Label: 'Trifft nicht zu',
      Value: 0
    },
    {
      Label: 'Trifft eher nicht zu',
      Value: 1
    },
    {
      Label: 'Neutral',
      Value: 2
    },
    {
      Label: 'Trifft eher zu',
      Value: 3
    },
    {
      Label: 'Trifft zu',
      Value: 4
    }
  ]

  selectedOption: any;

  onComplete() {

    this.data.Step1 = this.selectedOption.Value;
    this.complete.next();
  }

}