import { Component, Input, OnInit } from '@angular/core';
import { BlogService } from 'src/services/blog.service';

@Component({
  selector: 'app-blog-preview',
  templateUrl: './blog-preview.component.html',
  styleUrls: ['./blog-preview.component.scss']
})
export class BlogPreviewComponent {

  @Input()
  posts: any = [];

  constructor() { }


}
