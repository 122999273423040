<div class="placeholder shimmer" [class.revealed]="isRevealed">
  <div class="faux-image-wrapper">
    <div class="faux-image"></div>
  </div>

  <div #imgWrapper class="image-wrapper">
  </div>


  <div class="faux-text"></div>
  <div class="faux-text short"></div>
</div>