import { Directive, HostListener, Input } from '@angular/core';
import { GoogleAnalyticsService } from '@sc-ui';

@Directive({
  selector: '[gaTrackClick]'
})
export class GaTrackClickDirective {

  @Input()
  gaTrackClick = 'click';

  constructor(private googleAnalytics: GoogleAnalyticsService) { }

  @HostListener('click', ['$event']) onClick($event) {
    console.info('click tracked: ' + $event + ', ' + this.gaTrackClick);
    this.googleAnalytics.track(this.gaTrackClick);
  }

}
