import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef, Input } from '@angular/core';

@Component({
  selector: 'sc-modal-wizard-page',
  templateUrl: './modal-wizard-page.component.html',
  styleUrls: ['./modal-wizard-page.component.scss']
})
export class ModalWizardPageComponent implements OnInit, AfterViewInit {

  @ViewChild('pageContainer')
  pageContainer: ElementRef;

  @Input()
  definesHeight = false;

  state = 'out';

  ngOnInit() { }

  ngAfterViewInit() {
  }

  slideOutToLeft() {
    this.state = 'slide-out-to-left';
  }

  slideOutToRight() {
    this.state = 'slide-out-to-right';
  }

  slideInFromLeft() {
    this.state = 'slide-in-from-left';
  }

  slideInFromRight() {
    this.state = 'slide-in-from-right';
  }

  hide() {
    this.state = 'out';
  }

  show() {
    setTimeout(() => this.state = 'in');
  }
}
