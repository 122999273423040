<section anchor="contact mb-8">

  <div class="container">

      <div class="row align-items-center">

          <div class="col-12 col-md-6">

              <div class="row">

                <div class="col offset-md-1 mb-lg-3">
                  <h2 class="heading-xl heading-serif">Kontakt</h2>
                </div>
                
                <div class="col-md-10 offset-md-4">

                  <p class="h4">
                    +49-2171-77 67 67 0<br>
                    info@skillconomy.com
                  </p>
                   
                  <p class="h4">
                    Werkstättenstraße 37<br>
                    51379 Leverkusen
                  </p>
                  
                  <button class="btn btn-primary btn-round btn-lg mt-4" (click)="goToBooking()">Termin buchen</button>
                   
                </div>

              
              </div>


          </div>

          <div class="col-10 offset-1 mt-7 col-md-5 offset-md-1 mt-md-0 position-relative">
              <picture>
                  <img src="assets/img/sc-unternehmen-header.jpg" alt="" class="img-fluid w-100">
              </picture>
              <img class="swirl" src="./assets/img/swirl.svg" />
          </div>

      </div>

  </div>

  <app-svg-blob6></app-svg-blob6>

</section>


<app-job-check-cta></app-job-check-cta>