<footer class="sc-footer">

    <div class="container">

        <div class="row">

          <div class="mb-7 mb-md-0 col-md-4 col-lg-3">

            <h6 class="heading-uppercase">Produkt</h6>

            <nav>

              <ul class="list-unstyled">

                <li>
                  <a class="large" routerLink="/home">Video</a>
                </li>
                <li>
                  <a class="large" routerLink="/home" fragment="benefits">Vorteile</a>
                </li>
                <li>
                  <a class="large" routerLink="/home" fragment="garantie">Preise</a>
                </li>
                <li>
                  <a class="large" routerLink="/home" fragment="faq">FAQs</a>
                </li>
              </ul>

          </nav>
          
        </div>

          <div class="mb-7 mb-md-0 col-md-4 col-lg-3">
            
            <h6 class="heading-uppercase">Unternehmen</h6>
          
            <nav>

              <ul class="list-unstyled">

                <li>
                  <a class="large" routerLink="/unternehmen" fragment="werte">Werte</a>
                </li>
                <!-- <li>
                  <a routerLink="/unternehmen">Soziales Engagement</a>
                </li> -->
                <li>
                  <a class="large" routerLink="/impressum">Impressum</a>
                </li>
                <li>
                  <a class="large" routerLink="/datenschutz">Datenschutz</a>
                </li>

              </ul>

          </nav>

          </div>
          
          <!-- <div class="col-md-4 mb-md-0 col-lg-3">

            <h6>Themen</h6>

            <nav>

              <ul class="list-unstyled">                
              
                <li>
                  <a href="#">Garantie</a>
                </li>
                <li>
                  <a href="#">DSGVO/Compliance</a>
                </li>
                <li>
                  <a href="#">Unterschiede zum Headhunting</a>
                </li>
                <li>
                  <a href="#">Rahmenverträge</a>
                </li>
                <li>
                  <a href="#">Jobsite-Erstellung</a>
                </li>

              </ul>

          </nav>

          </div> -->

        </div>

        <div class="row meta mt-6 mt-md-6">
 
            <div class="col">
                     <ul class="list-unstyled list-inline">
                        <li class="list-inline-item small">&copy; {{today | date: 'yyyy'}} {{ model.copyright }} </li>
                        <!-- <li class="list-inline-item"><a routerLink="/impressum">Impressum</a></li>
                        <li class="list-inline-item"><a routerLink="/datenschutz">Datenschutz</a></li> -->
                    </ul>
             </div>
        </div>

        <!-- <img id="footer-bot-illu" src="./assets/svg/sc-footer.svg" /> -->
    </div>

</footer>
